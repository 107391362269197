import React, { Suspense } from 'react'
import { RouterProvider } from 'react-router-dom'
import router from './routes'

import './fonts/stylesheet.css'
import './styles/global.css'
function App() {
  return (
    <Suspense fallback='loading'>
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default App
