import { MenuItem, Select } from '@mui/material'
import { useFormik, useFormikContext } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const SelectField = ({
  uppername,
  touched,
  error,
  name,
  placeholder,
  helperText,
  className,
  errorMessage,
  options,
  setFieldValue,
  onChange
}) => {
  const { t } = useTranslation()
  const formikProps = useFormikContext()
  const [value, setValue] = useState(options[0].value)

  return (
    <div className='flex flex-col mb-5'>
      <div
        className={`${className} ${
          touched || error ? 'border border-errorBorder  ' : ''
        }`}
      >
        <div className='absolute top-1 left-3  justify-start text-[12px] text-gray-300 font-diodrumSemibold text-black'>
          <p>{uppername}</p>
        </div>
        <Select
          inputProps={{ IconComponent: () => null, placeholder: placeholder }}
          sx={{
            color: '#a1a1aa',
            paddingTop: '12px',
            fontFamily: 'inherit',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: 0
              },

            '&.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
              {
                fontWeight: 'normal'
              }
          }}
          labelId='demo-simple-select-label'
          id='position'
          value={value}
          name={name}
          onChange={(e) => {
            setValue(e.target.value)
            formikProps.setFieldValue(name, e.target.value)
          }}
          error={error}
          helperText={helperText}
        >
          {options?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{
                color: '#a8a29e',
                fontSize: '15px',
                fontFamily: 'inherit'
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>

        <div className='absolute inset-y-0 right-0 flex items-center pr-4 pt-2 pointer-events-none'>
          <svg
            className='h-4 w-4 text-gray-400'
            fill='none'
            stroke='black'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M19 9l-7 7-7-7'
            />
          </svg>
        </div>
      </div>

      <div className='relative h-2 flex justify-start text-start items-start'>
        {touched || error ? (
          <p className='text-[11px] text-red ml-1  text-errorBorder mt-[4px]   '>
            {errorMessage}
          </p>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default SelectField
