import React from 'react'

export default function Dropdown({
  width,
  position,
  handleClose,
  collaspeItem,
  dropdownItems,
  className
}) {
  return (
    <div
      className={`absolute ${
        position || 'top-24 right-6'
      }  left-auto w-auto modal__bg flex justify-end rounded-lg ${className}`}
      style={{ width }}
    >
      <div
        className={`bg-white inline-block w-full h-full text-left rounded-lg`}
      >
        {collaspeItem && (
          <div
            className='w-full p-2 text-lg  flex cursor-pointer'
            onClick={() => handleClose()}
          >
            {collaspeItem?.startIcon}
            <p className='ml-2 text-sm text-textBlack font-diodrumMedium'>
              {collaspeItem?.label}
            </p>
          </div>
        )}

        <ul className='p-4'>
          {dropdownItems?.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                item?.onClick && item?.onClick()
              }}
              className={`flex text-sm justify-${
                item.align || 'start'
              } p-2 cursor-${item.disabled ? 'default' : 'pointer'} text-${
                item.disabled ? 'gray' : ''
              }`}
            >
              {item?.startIcon}
              {item?.label}
              <span className='ml-3 -mb-1 inline-block'>{item?.endIcon}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
