import React, { useEffect, useState } from 'react'
import QuestionField from './form-components/question-field'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { CheckCircleRounded } from '@mui/icons-material'
import api from '../../api'
import RightAnswerSelectField from './form-components/right-answer-select-field'
import Popup from '../popup'
import usePopupVisible from '../../hooks/usePopupVisible'

export default function QuestionnaireFormRow({
  index,
  numb,
  id,
  question,
  answer1,
  answer2,
  answer3,
  correctAnswer,
  getQuestions,
  addNewQuestion,
  questionCatalog
}) {
  const { t, i18n } = useTranslation()
  const [isDeletePopupOpen, setDeletePoppup] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const { ref, isPopupVisible, setIsPopupVisible } = usePopupVisible(false)

  const toggleDeletePopup = () => {
    setDeletePoppup(!isDeletePopupOpen)
  }
  const options = [
    {
      label: t('answer A'),
      value: 'answerA'
    },
    {
      label: t('answer B'),
      value: 'answerB'
    },
    {
      label: t('answer C'),
      value: 'answerC'
    }
  ]

  const updateQuestion = async (values) => {
    await api
      .patch(`question/${id}`, { ...values, language: i18n.language })
      .then(async (response) => {
        if (response.data) {
          getQuestions()

          setDisabled(false)
        }
      })
      .catch((err) => setDisabled(false))
  }
  const addQuestion = async (values) => {
    await api
      .post(`question`, { ...values, language: i18n.language, questionCatalog })
      .then(async (response) => {
        if (response.data) {
          getQuestions()
          addNewQuestion()
          setDisabled(false)
        }
      })
      .catch((err) => {
        setDisabled(false)
        addNewQuestion()
      })
  }
  const deleteQuestion = async () => {
    await api
      .delete(`question/${id}`)
      .then(async (response) => {
        if (response.data) {
          getQuestions()
          setDisabled(false)
        }
      })
      .catch((err) => setDisabled(false))
  }

  const handleClosePopup = () => {
    setIsPopupVisible(false)
  }
  useEffect(() => {
    setIsPopupVisible(isDeletePopupOpen)
  }, [isDeletePopupOpen])

  useEffect(() => {
    !isPopupVisible && setDeletePoppup(false)
  }, [isPopupVisible])

  return (
    <div
      className='w-full border-r border-1 border-gray bg-slate-500 h-8'
      id='form'
    >
      <Formik
        enableReinitialize
        initialValues={{
          question: question,
          answerA: answer1,
          answerB: answer2,
          answerC: answer3,
          correctAnswer: correctAnswer ? correctAnswer : options[0].value
        }}
        onSubmit={async (values, formikHelpers) => {
          setDisabled(true)
          if (id === undefined || id === null) {
            await addQuestion(values)
          } else {
            await updateQuestion(values)
          }
        }}
        validationSchema={Yup.object().shape({
          question: Yup.string().required('question required'),
          answerA: Yup.string().required('answer A required'),
          answerB: Yup.string().required('answer B required'),
          answerC: Yup.string().required('answer C required'),
          correctAnswer: Yup.string().required('correct answer required')
        })}
      >
        {({
          errors,
          values,
          touched,
          onClick,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => {
          return (
            <form onSubmit={handleSubmit} className='bg-white'>
              <div className='grid grid-cols-5 divide-x-[1px] divide-gray '>
                <div className='grid col-span-2 gap-y-0 gap-x-2 w-full px-0 text-xs '>
                  <QuestionField
                    numb={index + 1}
                    className='text-xs w-full bg-bgField p-4 rounded-lg md:rounded-tl-md md:rounded-bl-md md:rounded-tr-none md:rounded-br-none'
                    type='text'
                    name='question'
                    placeholder={t('new question')}
                    value={values.question}
                    onChange={handleChange}
                    error={touched.question && Boolean(errors.question)}
                    helperText={touched.question && errors.question}
                    errorMessage={errors.question}
                  />
                </div>
                <div className='grid col-span-2 px-4'>
                  <div className='flex flex-row'>
                    <QuestionField
                      className='w-full bg-bgField p-4 rounded-lg md:rounded-tl-md md:rounded-bl-md md:rounded-tr-none md:rounded-br-none px-2'
                      type='text'
                      name='answerA'
                      placeholder={t('answer A')}
                      value={values.answerA}
                      onChange={handleChange}
                      error={touched.answerA && Boolean(errors.answerA)}
                      helperText={touched.answerA && errors.answerA}
                      errorMessage={errors.answerA}
                    />
                    <QuestionField
                      className='w-full bg-bgField p-4 rounded-lg md:rounded-tl-md md:rounded-bl-md md:rounded-tr-none md:rounded-br-none'
                      type='text'
                      name='answerB'
                      placeholder={t('answer B')}
                      value={values.answerB}
                      onChange={handleChange}
                      error={touched.answerB && Boolean(errors.answerB)}
                      helperText={touched.answerB && errors.answerB}
                      errorMessage={errors.answerB}
                    />{' '}
                    <QuestionField
                      className='w-full bg-bgField p-4 rounded-lg md:rounded-tl-md md:rounded-bl-md md:rounded-tr-none md:rounded-br-none'
                      type='text'
                      name='answerC'
                      placeholder={t('answer C')}
                      value={values.answerC}
                      onChange={handleChange}
                      error={touched.answerC && Boolean(errors.answerC)}
                      helperText={touched.answerC && errors.answerC}
                      errorMessage={errors.answerC}
                    />
                  </div>
                </div>
                <div className='flex justify-center items-center'>
                  {' '}
                  <RightAnswerSelectField
                    className='w-full flex flex-col  justify-center  h-5 bg-red text-sm  text-zinc-400  '
                    error={touched.position && Boolean(errors.position)}
                    type='select'
                    name='correctAnswer'
                    placeholder='Choose option'
                    value={values.correctAnswer}
                    onChange={handleChange}
                    options={options}
                    onClick={onClick}
                  />
                  <div className='flex justify-center flex-row items-center relative h-full border-l border-1 border-gray px-1'>
                    <button
                      type='submit'
                      value='rtl'
                      onClick={handleSubmit}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleSubmit()
                        }
                      }}
                      disabled={disabled}
                      className={`${
                        disabled ? 'cursor-not-allowed' : ''
                      } flex justify-center items-center text center pl-2 rounded-3xl w-6 h-6 text-sm text-white cursor-pointer `}
                    >
                      <CheckCircleRounded
                        fontSize='medium'
                        color='success'
                        sx={{
                          marginRight: '10px',
                          padding: '2px'
                        }}
                      />
                    </button>
                    <button
                      onClick={toggleDeletePopup}
                      type='button'
                      disabled={disabled}
                      className={`${disabled ? 'cursor-not-allowed' : ''} `}
                    >
                      <img
                        className='p-1'
                        width={44}
                        src='images/icons/zurucksetzen.png'
                      />
                    </button>
                    <Popup
                      open={isPopupVisible}
                      handleClose={handleClosePopup}
                      position={'fixed left-0 right-0 top-0 bg-gray'}
                    >
                      <div ref={ref} className='bg-white rounded-lg w-96'>
                        <div className='my-5 mx-6'>
                          <div className=' flex justify-center items-center text-center'>
                            <p className='w-4/5 font-diodrumMedium text-black mb-5'>
                              {t('delete-question')}
                            </p>
                          </div>

                          <div className='flex justify-evenly items-center mb-5'>
                            <button
                              className='w-12 bg-green hover:bg-red my-1 rounded-lg text-black'
                              type='button'
                              onClick={() => {
                                toggleDeletePopup()
                                deleteQuestion()
                              }}
                            >
                              {t('yes')}
                            </button>
                            <button
                              className='w-12 bg-red my-1 rounded-lg text-black'
                              type='button'
                              onClick={toggleDeletePopup}
                            >
                              {t('no')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Popup>
                  </div>
                  <div></div>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
