const RoundedComponent = ({ renderNumb }) => {
  return (
    <div className='flex justify-center items-center'>
      <div className='grid place-items-center w-5 h-full bg-middleLightBlue rounded-full '>
        <span className=' text-center text-xs text-white font-diodrumSemibold '>
          {renderNumb}
        </span>
      </div>
    </div>
  )
}

export default RoundedComponent
