export default function CalendarIcon({ className }) {
  return (
    <img
      className={`h-4 ${className}`}
      alt=''
      src='/images/icons/calendar.png'
      width='16'
    />
  )
}
