import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { Link } from 'react-router-dom'
import store from '../store'
import { setSosButtonClicked } from '../store/slices/sos-slice'
import Menu from '../components/menu/main'

const drawerWidth = 240

function Sidebar(props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div className='mt-6'>
      <div className='mb-4'>
        <Link to={'/'}>
          <a>
            <img className='w-32 m-auto' src='/images/dupont-logo.png' />
          </a>
        </Link>
      </div>
      {props.userRole !== 'team_assistant' && (
        <Menu userRole={props.userRole} />
      )}
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined
  if (!props.isOpen)
    return (
      <div className='flex flex-col justify-end h-screen p-2 pb-7'>
        <button
          onClick={props.handleSidebarToggle}
          title='Open sidebar'
          style={{ transform: 'rotate(180deg)' }}
        >
          <img alt='' src='/images/icons/sidebar/collapse.png' width='20' />
        </button>
      </div>
    )
  return (
    <Box
      component='nav'
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 }
      }}
      aria-label='mailbox folders'
    >
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth
          }
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant='permanent'
        sx={{
          background: '#323232',
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth
          }
        }}
        open
      >
        <div className='bg-lightBlue h-full flex flex-col justify-between overflow-y-hidden'>
          {drawer}
          <div className='flex justify-between p-3 pb-5'>
            {props.userRole !== 'team_assistant' && (
              <Link
                to='/sos'
                onClick={() => store.dispatch(setSosButtonClicked(true))}
              >
                <a className='flex bg-button-red rounded-xl py-2 px-4 text-xs text-white'>
                  <img
                    className='mr-3'
                    alt=''
                    src='/images/icons/sidebar/sos-icon-white.png'
                    width='14'
                  />
                  SOS
                </a>
              </Link>
            )}
            {/* <button onClick={props.handleSidebarToggle} title='Close sidebar'>
              <img alt='' src='/images/icons/sidebar/collapse.png' width='20' />
            </button> */}
          </div>
        </div>
      </Drawer>
    </Box>
  )
}

Sidebar.propTypes = {
  window: PropTypes.func
}

export default Sidebar
