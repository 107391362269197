import React from 'react'
import Page from '../layout'
import PreCheckinForm from '../components/forms/pre-check-in'
import { useTranslation } from 'react-i18next'

export default function PreCheckin() {
  const { t } = useTranslation()
  return (
    <Page pageName={t('pre check-in')}>
      <div className='h-auto'>
        <PreCheckinForm />
      </div>
    </Page>
  )
}
