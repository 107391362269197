import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import isEmail from 'validator/lib/isEmail'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { useTranslation } from 'react-i18next'
import SelectField from './form-components/select-field'
import TextFieldWithTitle from './form-components/text-field-with-title'
import RoundedComponent from '../cards/rounded'
import api from '../../api'
import Loader from '../../components/loader'

const AddUserForm = ({ getUsers }) => {
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [displayMessage, setDisplayMessage] = useState(false)

  const showMessage = () => {
    setDisplayMessage(true)
    setTimeout(() => {
      setDisplayMessage(false)
    }, 5000)
  }

  const { t } = useTranslation()

  const options = [
    {
      label: t('subscriber'),
      value: 'subscriber',
    },
    {
      label: t('administrator'),
      value: 'administrator',
    },
    {
      label: t('team_assistant'),
      value: 'team_assistant',
    },
  ]
  const addUser = async (values) => {
    setLoading(true)
    await api
      .post('user', {
        email: values.email,
        password: values.password,
        role: values.position,
      })
      .then(async (response) => {
        if (response.data) {
          setMessage('description.user-successsful')
          setStatus('success')
          setLoading(false)
          showMessage()
          getUsers()
        }
      })
      .catch((err) => {
        if (err && err.response.data.code === 'already_exists') {
          setMessage('user-exists')
        } else setMessage('description.user-error')
        setStatus('error')
        showMessage()
        setLoading(false)
      })
  }

  return (
    <div className='flex flex-col'>
      <Formik
        initialValues={{
          position: 'subscriber',
          email: '',
          password: '',
        }}
        onSubmit={(values, { resetForm }) => {
          addUser(values)
          resetForm()
        }}
        validationSchema={Yup.object().shape({
          position: Yup.string().required('position required'),
          email: Yup.string()
            .email('invalid email')
            .test((value) => isEmail(value))
            .required('mandatory field'),
          password: Yup.string()
            .min(8, 'password required')
            .required('mandatory field')
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
              'required characters'
            ),
        })}
      >
        {({
          errors,
          values,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit} className='relative ml-7'>
              <div className='w-[55%]'>
                <div className='flex justify-start mb-5 col-span-2 '>
                  <RoundedComponent renderNumb='1' />
                  <p className='text-sm text-black ml-3 uppercase'>
                    {t('login data')}
                  </p>
                </div>
                <div className='grid grid-rows-1 ml-8'>
                  <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full '>
                    <SelectField
                      uppername='Position'
                      name={'position'}
                      placeholder={'Selected Position'}
                      value={values.position}
                      className='w-full flex flex-col relative justify-center h-14 bg-white border-transparent text-sm  text-zinc-400 shadow-lg rounded-sm'
                      error={touched.position && Boolean(errors.position)}
                      helperText={touched.position && errors.position}
                      errorMessage={errors.position}
                      options={options}
                    />
                  </div>
                  <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full '>
                    <TextFieldWithTitle
                      uppername='E-Mail'
                      placeholder={'max@mustermann.de'}
                      type='text'
                      name='email'
                      value={values.email}
                      className='w-full flex flex-col relative  justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      errorMessage={errors.email}
                    />
                    <TextFieldWithTitle
                      uppername={t('password')}
                      placeholder={t('password')}
                      type='passrowd'
                      name='password'
                      value={values.password}
                      className='w-full flex flex-col relative justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      errorMessage={errors.password}
                    />
                  </div>
                  <div className='flex justify-start flex-col mb-5 mt-8 relative'>
                    <button
                      type='submit'
                      value='rtl'
                      disabled={loading}
                      className={`bg-green rounded-md w-full h-12 text-sm text-white ${
                        loading === false
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed'
                      }`}
                      id='add-user'
                    >
                      {loading ? (
                        <Loader text={t('loading') + '...'} />
                      ) : (
                        <>
                          <CheckCircleRoundedIcon
                            fontSize='large'
                            sx={{
                              color: '#ffffff',
                              marginRight: '10px',
                              padding: '3px',
                            }}
                          />
                          {t('confirm')}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
      {status && message && displayMessage && (
        <p
          className={`ml-14 pl-1 ${
            status === 'success' ? 'text-green' : 'text-red'
          }`}
        >
          {t(message)}
        </p>
      )}
    </div>
  )
}
export default AddUserForm
