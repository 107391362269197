import React from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export default function SplineChart({
  label,
  value,
  series,
  rate,
  colors,
  categories,
  textTransform
}) {
  const dates = categories?.map((element) => {
    const date = new Date(element)
    let formattedDate = new moment(date).format('DD.MM.YYYY HH:mm')

    if (formattedDate.endsWith('24:00')) {
      formattedDate = formattedDate.replace('24:00', '00:00')
    }
    return formattedDate
  })

  const { t } = useTranslation()
  const options = {
    colors,
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    toolbar: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2.3
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    xaxis: {
      labels: {
        show: false
      },

      categories: dates
    },

    tooltip: {
      x: {
        format: 'dd/MM/yyyy HH:mm'
      }
    },
    grid: {
      show: false
    }
  }

  function getRateColor() {
    if (rate < 0) return 'text-red'
    return 'text-green'
  }
  return (
    <div
      className='relative bg-white rounded-xl mr-8 p-0'
      style={{ minHeight: '170px' }}
    >
      <div className='relative flex justify-between top-6'>
        <div className='pl-5 text-left'>
          <span
            className={`block mb-1 text-sm text-textLightBlue ${textTransform}`}
          >
            {label}
          </span>
          <h3 className='text-3xl text-black font-diodrumSemibold mt-3'>
            {value}
          </h3>
        </div>
        <div className='relative mt-2 pr-4 text-right top-6'>
          <h5 className={`text-sm ${getRateColor()} -mb-1`}>{rate || 0}%</h5>
          <span className='block text-xs text-textLightBlue'>
            {t('this week')}
          </span>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          left: '-21px',
          right: '0',
          bottom: '-30px'
        }}
      >
        <div id='chart'>
          <Chart
            options={options}
            series={series}
            className={series[0]?.style}
            type='area'
            height={110}
          />
        </div>
      </div>
    </div>
  )
}
