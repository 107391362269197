import { createSlice } from '@reduxjs/toolkit'

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    lastNotification: null,
    countNotification: 0
  },
  reducers: {
    setCountNotification(state, action) {
      state.countNotification = action.payload
    },
    setLastNotification(state, action) {
      state.lastNotification = action.payload
    }
  }
})

const { actions, reducer } = notificationSlice

export const { setCountNotification, setLastNotification } = actions

export default reducer
