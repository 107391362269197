import moment from 'moment'

export function formatDate(date, dateAndTime, backend, dafina) {
  if (!date) return
  const updatedDateType = new Date(date)

  const dateNow = updatedDateType

    .toLocaleDateString(backend ? 'en-US' : 'en-GB', {
      year: '2-digit',
      day: '2-digit',
      month: '2-digit'
    })
    .replaceAll('/', '.')
  const formattedTime = updatedDateType.toLocaleTimeString(
    backend ? 'en-US' : 'en-GB',
    {
      hour: '2-digit',
      minute: '2-digit'
    }
  )
  if (dateAndTime === true) {
    const newDate = dateNow.replaceAll('.', '-')
    const newDateAndTime = newDate + ' ' + formattedTime
    const returnedVal = new Date(
      newDateAndTime.replace(/-/g, '/')
    ).toISOString()
    return returnedVal
  } else {
    return dateNow
  }
}

export function formatDateWithTime(date, hours, minutes, seconds) {
  const dateFormated = new Date(
    date?.getFullYear(),
    date?.getMonth(),
    date?.getDate(),
    hours || 0,
    minutes || 0,
    seconds || 0
  )
  return dateFormated
}

export const formatTime = (time) => {
  const updatedTimeType = new Date(time)
  let currentHours = updatedTimeType.getHours()
  currentHours = ('0' + currentHours).slice(-2)
  let currenMinutes = updatedTimeType.getMinutes()
  currenMinutes = ('0' + currenMinutes).slice(-2)

  let formatedTime = ''
  formatedTime = updatedTimeType ? currentHours + ':' + currenMinutes : ''
  return formatedTime
}

export const isValidTimeFormat = (time) => {
  const timeRegex = /^(?:[01]\d|2[0-4]):[0-5]\d$/
  return timeRegex.test(time)
}

export const formatTimeToISOString = (time) => {
  const today = moment()
  const parsedTime = moment(time, 'HH:mm')
  today.set({
    hour: parsedTime.hours(),
    minute: parsedTime.minutes(),
    second: 0,
    millisecond: 0
  })
  const formattedDate = today.format('YYYY-MM-DD HH:mm:ss')
  const isoString = moment(formattedDate).toISOString()
  const formattedTime = moment.utc(isoString).format('HH:mm')
  return formattedTime
}

export const getStatusLabel = (status, t) => {
  if (status === 'draft_check_in') return t('waiting for approval')
  if (status === 'check_out') return t('checked out')
  if (status === 'check_in') return t('checked in')
  return t('pre check in')
}
export const getStatusLabelOnModal = (status, t) => {
  if (status === 'check_out') return t('check-out')
  if (status === 'check_in') return t('check-in')
  return t('pre check-in')
}

export const getType = (type, t) => {
  if (type === 'visitor') return t('visitor')
  if (type === 'external_company') return t('external company')
  if (type === 'employee') return t('employee')
  if (type === 'worker') return t('worker')
  if (type === 'Anonymised') return t('anonymised')
  return
}

export const showVisitorData = (name, t, nameType) => {
  if (name !== 'Anonymised' && nameType === 'first-name')
    return name?.split(' ')[0]
  if (name !== 'Anonymised' && nameType === 'last-name')
    return name?.split(' ')[1]
  if (
    name === 'Anonymised' ||
    name === 'anonymised' ||
    name?.includes('anonymised')
  )
    return t('anonymised')
  return name
}
