import React from 'react'

const Popup = ({
  open,
  handleClose,
  children,
  position,
  itemsAligned,
  backgroundColor,
  fullWidthOverlay,
  backgroundOpacity
}) => {
  if (!open) return
  return (
    <div className='flex justify-center items-center'>
      <div
        id='defaultModal'
        tabIndex='-1'
        aria-hidden='true'
        modal={`open`}
        className={`flex justify-center items-${itemsAligned || 'center'} ${
          position || 'absolute top-0'
        } w-full z-50  h-full p-4 overflow-x-hidden overflow-y-auto ${
          backgroundColor || 'bg-lightGray'
        } ${backgroundOpacity || 'bg-opacity-60'}`}
        style={fullWidthOverlay ? { zIndex: '9999' } : {}}
      >
        {children}
      </div>
    </div>
  )
}
export default Popup
