import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import RoundedComponent from './cards/rounded'

const UserRoleList = ({ role, users }) => {
  return (
    <div className='mb-8 bg-white p-3 rounded'>
      <p className='text-xs font-bold mb-2'>{role}</p>
      <ul className='mb-3'>
        {users?.map((user) => (
          <li className='py-1'>{user?.email}</li>
        ))}
      </ul>
    </div>
  )
}

const UserList = ({
  administratorUsers,
  subscribersUsers,
  teamAssistantUsers
}) => {
  const { t } = useTranslation()

  const allUsers = [
    { label: t('administrators'), list: administratorUsers },
    { label: t('subscribers'), list: subscribersUsers },
    { label: t('team_assistants'), list: teamAssistantUsers }
  ]
  return (
    <div className='flex flex-col'>
      <div className='relative ml-7 mt-14'>
        <div className='flex justify-start mb-2 col-span-2 '>
          <RoundedComponent renderNumb='3' />
          <p className='text-sm text-black ml-3 uppercase'>{t('user-list')}</p>
        </div>
        <div className='grid lg:grid-cols-3 gap-4 ml-8 mr-5'>
          {allUsers.map((item) => {
            return (
              <div className='col-span-1'>
                <UserRoleList role={item.label} users={item.list} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default UserList
