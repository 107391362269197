import React, { useEffect, useState, useCallback } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { useSelector } from 'react-redux'
import Popup from '../components/popup'
import SearchComponent from '../components/search'
import { unAuthUser } from '../helpers/auth'
import { getStatusLabel } from '../helpers'
import Dropdown from '../components/dropdown'
import LogoutIcon from '@mui/icons-material/Logout'
import { useTranslation, Trans } from 'react-i18next'
import store from '../store'
import { setLastNotification } from '../store/slices/notification-slice'
import usePopupVisible from '../hooks/usePopupVisible'
import api from '../api'

const Topbar = ({ pageName, textColor, userRole }) => {
  const [activeDropdown, setActiveDropdown] = useState(null)
  const { ref, isPopupVisible, setIsPopupVisible } = usePopupVisible(false)
  const [profile, setProfile] = useState(false)
  const [searchPopup, setSearch] = useState(false)
  const [countNotification, setCountNotification] = useState(0)
  const [notifyOpen, setNotifyOpen] = useState(false)
  const [notifyData, setNotifyData] = useState([])

  const navigate = useNavigate()
  const location = useLocation()

  const pathname = location.pathname.replace('/', '')

  const { i18n, t } = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  const { searchQuery } = useSelector((state) => state.search)
  const { lastNotification } = useSelector((state) => state.notification)

  const handleClosePopup = () => {
    setActiveDropdown(null)
    setIsPopupVisible(false)
  }

  useEffect(() => {
    setIsPopupVisible(profile)
  }, [profile])

  useEffect(() => {
    setIsPopupVisible(searchPopup)
  }, [searchPopup])

  useEffect(() => {
    setIsPopupVisible(notifyOpen)
  }, [notifyOpen])

  useEffect(() => {
    !isPopupVisible && setProfile(false)
    !isPopupVisible && setSearch(false)
    !isPopupVisible && setNotifyOpen(false)
  }, [isPopupVisible])

  const getNotifications = useCallback(async () => {
    await api
      .get(
        `visitor/notify?createdAt=${localStorage.getItem('last_notification')}`
      )
      .then(async (response) => {
        localStorage.setItem('last_notification', response.data.createdAt)
        localStorage.setItem(
          'count_notify',
          parseInt(localStorage.getItem('count_notify')) + response.data.count
        )
        store.dispatch(
          setLastNotification(localStorage.getItem('last_notification'))
        )
        setCountNotification(parseInt(localStorage.getItem('count_notify')))
      })
      .catch(() => {})
  }, [])

  const getVisitor = useCallback(async () => {
    await api.get(`visitor?visitorHistory=false`).then((res) => {
      setNotifyData(res.data.results)
    })
  }, [lastNotification, countNotification])

  useEffect(() => {
    const handle = setInterval(getNotifications, 10000)
    return () => {
      clearInterval(handle)
    }
  }, [])

  useEffect(() => {
    getVisitor()
  }, [getVisitor, countNotification])

  useEffect(() => {
    getNotifications()

    if (i18n.language === 'en-GB' || i18n.language === 'en-US') {
      changeLanguage('en')
    } else if (i18n.language === 'de-DE') {
      changeLanguage('de')
    }
  }, [])

  return (
    <>
      <Box
        id='topbar'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pb: 2,
          pr: 2,
          px: 2,
          pl: 1
        }}
      >
        <Box>
          <p
            className={` ${
              textColor || 'text-textGray'
            } text-2xl font-diodrumSemibold pl-5 `}
          >
            {pageName}
          </p>
        </Box>
        <Box className='flex' gap={1.5} id='topbar-right'>
          <Link to={'/pre-checkin'}>
            <a>
              <img
                alt=''
                src='/images/icons/topbar/add-user-icon.png'
                width='45'
                className='m-auto'
              />
            </a>
          </Link>
          {userRole !== 'team_assistant' && (
            <>
              {!['add-user', 'pre-checkin', 'settings'].includes(pathname) && (
                <button
                  className='w-10 ml-3'
                  onClick={() => {
                    setSearch(!searchPopup)
                    setActiveDropdown('search')
                  }}
                >
                  <img
                    alt=''
                    src='/images/icons/topbar/search.png'
                    width='20'
                    className='m-auto'
                  />
                </button>
              )}
              <button
                className='w-10 mr-3 relative'
                onClick={() => {
                  setNotifyOpen(!notifyOpen)
                  localStorage.setItem('count_notify', 0)
                  setCountNotification(0)
                  setActiveDropdown('notify')
                }}
              >
                {countNotification !== 0 && !isNaN(countNotification) && (
                  <span className='absolute right-0 -top-1 bg-red text-white text-[14px] w-[22px] rounded-lg h-[22px] m-auto'>
                    {countNotification}
                  </span>
                )}
                <img
                  alt=''
                  src='/images/icons/topbar/notifications-icon.png'
                  width='20'
                  className='m-auto'
                />
              </button>
            </>
          )}

          <button
            className='inline-flex mr-1'
            onClick={() => {
              setProfile(!profile)
              setActiveDropdown('profile')
            }}
          >
            {(
              <div className=' flex justify-start items-start mr-2'>
                <Avatar
                  sx={{
                    bgcolor: '#d7d7d7',
                    width: 48,
                    height: 48,
                    borderRadius: '20px',
                    margin: 'auto'
                  }}
                  aria-label='recipe'
                />
              </div>
            ) || (
              <Avatar
                sx={{
                  bgcolor: '#d7d7d7',
                  width: 45,
                  height: 45,
                  borderRadius: '25px',
                  marginRight: '7px'
                }}
                aria-label='recipe'
              />
            )}
            <img
              alt=''
              src='/images/icons/topbar/chevron-down.png'
              width='15'
              className='m-auto'
            />
          </button>
        </Box>
      </Box>
      <Popup open={isPopupVisible} handleClose={handleClosePopup}>
        <div ref={ref}>
          {activeDropdown === 'profile' ? (
            <Dropdown
              width={150}
              position='top-20 right-10'
              handleClose={handleClosePopup}
              dropdownItems={[
                {
                  startIcon: (
                    <img
                      alt=''
                      src='/images/united-kingdom.png'
                      width='20'
                      className='inline-block mr-3'
                    />
                  ),
                  label: t('english'),
                  onClick: () => {
                    changeLanguage('en')
                    setProfile(!profile)
                    setActiveDropdown('')
                  }
                },
                {
                  startIcon: (
                    <img
                      alt=''
                      src='/images/germany-flag.png'
                      width='20'
                      className='inline-block mr-3'
                    />
                  ),
                  label: t('german'),
                  onClick: () => {
                    changeLanguage('de')
                    setProfile(!profile)
                    setActiveDropdown('')
                  }
                },
                {
                  startIcon: <LogoutIcon sx={{ marginRight: '10px' }} />,
                  label: t('logout'),
                  align: 'start',
                  onClick: () => {
                    unAuthUser()
                    navigate('/login')
                  }
                }
              ]}
            />
          ) : activeDropdown === 'search' ? (
            <SearchComponent
              handleClose={handleClosePopup}
              position='top-20 right-16'
              searchQuery={searchQuery}
            />
          ) : (
            activeDropdown === 'notify' && (
              <Dropdown
                width={290}
                position='top-20 right-10 overflow-y-auto max-h-[90vh]'
                handleClose={handleClosePopup}
                dropdownItems={
                  notifyData?.length
                    ? notifyData?.map((item) => {
                        return {
                          startIcon: item.profilePhoto ? (
                            <img
                              alt=''
                              width={32}
                              src={item.profilePhoto}
                              className='inline-block mr-2 rounded-2xl h-8'
                            />
                          ) : (
                            <Avatar
                              sx={{
                                bgcolor: '#d7d7d7',
                                width: 32,
                                height: 32,
                                borderRadius: '25px',
                                display: 'inline-block',
                                marginRight: '5px',
                                display: 'flex'
                              }}
                              aria-label='recipe'
                            />
                          ),
                          label:
                            item.fullName +
                            ' ' +
                            t('is') +
                            ' ' +
                            getStatusLabel(item.status, t),
                          align: 'left',
                          onClick: () => {
                            navigate(
                              `/check${
                                item.status !== 'check_out' ? 'ins' : 'outs'
                              }`
                            )
                            setNotifyOpen(false)
                          }
                        }
                      })
                    : [
                        {
                          label: <Trans i18nKey='description.no-notification' />
                        }
                      ]
                }
              />
            )
          )}
        </div>
      </Popup>
    </>
  )
}

export default Topbar
