import React, { useState, useEffect } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default ({ userRole }) => {
  const location = useLocation()
  const [currentLocation, setCurrentLocation] = useState('')

  const { t } = useTranslation()

  const activePath = location.pathname
  useEffect(() => {
    setCurrentLocation(activePath)
  }, [activePath])

  let navigation = [
    {
      label: t('overview'),
      to: '/',
      activeIcon: (
        <img alt='' src='/images/icons/sidebar/ubersicht.png' width='20' />
      ),
      icon: (
        <img alt='' src='/images/icons/sidebar/ubersicht-gray.png' width='20' />
      )
    },
    {
      label: t('check-ins'),
      to: '/checkins',
      icon: <img alt='' src='/images/icons/sidebar/check-ins.png' width='20' />,
      activeIcon: (
        <img
          alt=''
          src='/images/icons/sidebar/check-ins-green.png'
          width='20'
        />
      )
    },
    {
      label: t('check-outs'),
      to: '/checkouts',
      icon: (
        <img alt='' src='/images/icons/sidebar/check-outs.png' width='20' />
      ),
      activeIcon: (
        <img
          alt=''
          src='/images/icons/sidebar/check-outs-green.png'
          width='20'
        />
      )
    }
  ]
  if (userRole === 'administrator') {
    navigation = [
      ...navigation,
      {
        label: t('history'),
        to: '/history',
        icon: (
          <img alt='' src='/images/icons/sidebar/historie.png' width='20' />
        ),
        activeIcon: (
          <img
            alt=''
            src='/images/icons/sidebar/historie-green.png'
            width='20'
          />
        )
      },
      {
        label: t('add user'),
        to: '/add-user',
        icon: (
          <img
            alt=''
            src='/images/icons/sidebar/nutzer-hinzufugen.png'
            width='20'
          />
        ),
        activeIcon: (
          <img
            alt=''
            src='/images/icons/sidebar/nutzer-hinzufugen-green.png'
            width='20'
          />
        )
      },
      {
        label: t('settings'),
        to: '/settings',
        icon: (
          <img
            alt=''
            src='/images/icons/sidebar/einstellungen.png'
            width='20'
          />
        ),
        activeIcon: (
          <img
            alt=''
            src='/images/icons/sidebar/einstellungen-green.png'
            width='20'
          />
        )
      }
    ]
  }

  return (
    <List>
      {navigation?.map((item, index) => (
        <ListItem
          key={index}
          disablePadding
          sx={{ color: currentLocation === item.to ? '#0fb2ab' : '#565682' }}
        >
          <Link to={item.to} className='w-full'>
            <ListItemButton
              sx={{
                marginRight: '30px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                backgroundColor: currentLocation === item.to ? '#e5eaf2' : '',
                fontWeight: '500'
              }}
            >
              <ListItemIcon sx={{ marginLeft: '20px' }}>
                {currentLocation === item.to ? item?.activeIcon : item?.icon}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ style: { fontWeight: '500' } }}
                primary={item?.label}
              />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </List>
  )
}
