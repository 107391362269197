import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Popup from '../components/popup'
import Dropdown from '../components/dropdown'
import usePopupVisible from '../hooks/usePopupVisible'

const UnAuthLayoutTopbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { ref, isPopupVisible, setIsPopupVisible } = usePopupVisible(false)
  const { t, i18n } = useTranslation()

  const handleCloseDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleClosePopup = () => {
    setIsPopupVisible(false)
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  useEffect(() => {
    setIsPopupVisible(isDropdownOpen)
  }, [isDropdownOpen])

  useEffect(() => {
    !isPopupVisible && setIsDropdownOpen(false)
  }, [isPopupVisible])

  return (
    <>
      <div className='flex mb-4 justify-between'>
        <img
          src='/images/dupont-logo.png'
          alt='image'
          className='w-28 h-auto'
        />
        <div className='justify-end flex items-center'>
          <button className='uppercase mr-3' onClick={handleCloseDropdown}>
            {t('language')}
          </button>

          <p>LOG IN</p>
        </div>
      </div>
      <Popup
        open={isPopupVisible}
        handleClose={handleClosePopup}
        position='absolute right-0 top-20 mt-2'
      >
        <div ref={ref}>
          <Dropdown
            width={150}
            position='absolute top-0 right-24 mr-2 '
            className='shadow-xl border-t border-1 border-lightGray'
            handleClose={handleClosePopup}
            dropdownItems={[
              {
                startIcon: (
                  <img
                    alt=''
                    src='/images/united-kingdom.png'
                    width='20'
                    className='inline-block mr-3'
                  />
                ),
                label: t('english'),
                onClick: () => {
                  handleClosePopup()
                  changeLanguage('en')
                }
              },
              {
                startIcon: (
                  <img
                    alt=''
                    src='/images/germany-flag.png'
                    width='20'
                    className='inline-block mr-3'
                  />
                ),
                label: t('german'),
                onClick: () => {
                  handleClosePopup()
                  changeLanguage('de')
                }
              }
            ]}
          />
        </div>
      </Popup>
    </>
  )
}

export default UnAuthLayoutTopbar
