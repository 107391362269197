import { configureStore } from '@reduxjs/toolkit'
import searchReducer from './slices/search-slice'
import sosReducer from './slices/sos-slice'
import notificationReducer from './slices/notification-slice'
import checkinReducer from './slices/checkins-slice'

const store = configureStore({
  reducer: {
    search: searchReducer,
    sos: sosReducer,
    notification: notificationReducer,
    checkins: checkinReducer
  }
})

export default store
