import React, { useEffect, useState } from 'react'
import { DateRangePicker, Calendar } from 'react-date-range'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useTranslation } from 'react-i18next'
import * as rdrLocales from 'react-date-range/dist/locale'
import { formatDateWithTime } from '../helpers'

export function DatePicker({
  type,
  dateRange,
  setDateRange,
  setOpen,
  setTimeName,
  dashboard,
  disablePrevievDates
}) {
  const initialselectionRange =
    type === 'date-picker'
      ? new Date()
      : {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
  const [selectionRange, setSelectionRange] = useState(initialselectionRange)
  const [focusedRange, setFocusedRange] = useState([0, 0])
  const [reset, setReset] = useState(false)

  const { t, i18n } = useTranslation()

  function handleSelect(value) {
    setReset(false)
    setSelectionRange(value.range1 ? value.range1 : value.selection || value)
  }

  const handleSaveDateRange = () => {
    if (selectionRange?.startDate === undefined) {
      setDateRange(selectionRange)
    } else {
      const selectionStartDate = selectionRange.startDate
      const SelectionEndDate = selectionRange.endDate

      const startDate = formatDateWithTime(selectionStartDate, 0, 0, 0)
      const endDate = formatDateWithTime(SelectionEndDate, 23, 59, 59)

      const formatedSelectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection'
      }
      setDateRange(formatedSelectionRange)
    }
    dashboard && !reset && setTimeName('')

    setOpen()
  }

  const handleCancelDateRange = () => {
    setReset(true)
    dashboard && setTimeName(t('today'))
    if (type === 'date-picker') {
      setDateRange(null)
      setSelectionRange(null)
    } else {
      setSelectionRange(initialselectionRange)
      setDateRange(type === 'date-range-picker' ? initialselectionRange : null)
      setFocusedRange([0, 0])
    }
  }

  useEffect(() => {
    if (dateRange) setSelectionRange(dateRange)
  }, [dateRange])

  const locale = i18n.language === 'en' ? rdrLocales.enUS : rdrLocales.de

  return (
    <div className='bg-white calendar-wrapper border border-1 border-gray w-fit rounded-xl overflow-auto'>
      <span
        onClick={() => setOpen()}
        className='flex justify-end mt-2 mr-3 cursor-pointer'
      >
        <img width={10} src='/images/icons/close-icon.png' />
      </span>
      {type === 'date-picker' ? (
        <Calendar
          locale={locale}
          color='#e3011b'
          date={selectionRange}
          onChange={handleSelect}
          preventSnapRefocus={true}
          minDate={disablePrevievDates && new Date()}
          showSelectionPreview={false}
        />
      ) : (
        <DateRangePicker
          locale={locale}
          rangeColors={['#edf2f5']}
          showDateDisplay={false}
          // staticRanges={[]}
          // inputRanges={[]}
          minDate={disablePrevievDates && new Date()}
          moveRangeOnFirstSelection={false}
          ranges={[selectionRange]}
          onChange={handleSelect}
          direction='vertical'
          focusedRange={focusedRange}
          onRangeFocusChange={setFocusedRange}
        />
      )}
      <div className='w-full h-9 px-4 mb-1 mt-2 flex justify-between'>
        <button
          type='button'
          onClick={handleCancelDateRange}
          className='text-xs text-red p-1 rounded-md hover:bg-lightGray'
        >
          <HighlightOffIcon />
          <span className='ml-1'>{t('reset to default')}</span>
        </button>
        <button
          type='button'
          onClick={handleSaveDateRange}
          className='text-xs text-green p-1 rounded-md hover:bg-lightGray'
        >
          <CheckCircleOutlineIcon />
          <span className='ml-1 capitalize'>{t('save')}</span>
        </button>
      </div>
    </div>
  )
}
