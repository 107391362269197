import React from 'react'
import Page from '../layout'
import SettingsForm from '../components/forms/settingsForm'
import { useTranslation } from 'react-i18next'

export default function Settings() {
  const { t } = useTranslation()
  return (
    <Page pageName={t('settings')}>
      <div className='h-auto'>
        <SettingsForm />
      </div>
    </Page>
  )
}
