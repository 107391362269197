import { createSlice } from '@reduxjs/toolkit'

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchQuery: ''
  },
  reducers: {
    setSearchQuery(state, action) {
      state.searchQuery = action.payload
    }
  }
})

const { actions, reducer } = searchSlice

export const { setSearchQuery } = actions

export default reducer
