import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

const TextFieldComponent = ({
  uppername,
  startIcon,
  endIcon,
  touched,
  error,
  name,
  type,
  placeholder,
  onChange,
  value,
  helperText,
  className,
  errorMessage,
  disabled
}) => {
  const { t } = useTranslation()

  return (
    <div className='mb-5 relative block'>
      <div className='text-sm pl-3 mb-2 text font-diodrumSemibold text-lightBlue'>
        <p>{uppername}</p>
      </div>
      <div
        className={`${className} ${
          touched || error ? 'border border-errorBorder ' : ''
        }`}
      >
        <div className='flex justify-between items-center w-full'>
          <div className='flex flex-row justify-start items-center w-full h-full'>
            <img
              className='inline-block mr-3'
              src={startIcon}
              alt='left arrow'
              width='20'
            />
            <Field
              className='border-none outline-none w-full rounded-3xl py-3'
              type={type}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              error={error}
              helperText={helperText}
              disabled={disabled}
            />
          </div>

          {endIcon && <div className='mr-3'>{endIcon}</div>}
        </div>
      </div>

      <div className='relative h-1 mt-1'>
        {touched ||
          (error && (
            <span className='block'>
              <p className='text-[11px] text-red ml-1 text-errorBorder '>
                {t(errorMessage)}
              </p>
            </span>
          ))}
      </div>
    </div>
  )
}

export default TextFieldComponent
