import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { useTranslation, Trans } from 'react-i18next'
import TextFieldWithTitle from './form-components/text-field-with-title'
import RoundedComponent from '../cards/rounded'
import api from '../../api'
import Loader from '../../components/loader'

const ResetUserPasswordForm = () => {
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)
  const [displayMessage, setDisplayMessage] = useState(false)

  const showMessage = () => {
    setDisplayMessage(true)
    setTimeout(() => {
      setDisplayMessage(false)
    }, 5000)
  }

  const { t } = useTranslation()

  const resetPassword = async (values) => {
    setLoading(true)
    setPasswordReset(true)
    await api
      .post('user/forgot-password', {
        email: values.email,
      })
      .then(async (response) => {
        if (response.data) {
          setMessage('password reset link has been sent')
          setStatus('success')
          setLoading(false)
          showMessage()
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response.data.code === 'validation_error' &&
          err.response.data.message ===
            'New Password must be between 8 and 50 characters'
        ) {
          setMessage('validation error')
        } else if (err.response.data.code === 'expired_token') {
          setMessage('expired_token')
        } else if (err.response.data.code === 'invalid_password_reset_token') {
          setMessage('invalid_password_reset_token')
        } else setMessage('user not found')
        setStatus('error')
        setLoading(false)
        showMessage()
        setPasswordReset(false)
      })
  }
  return (
    <div className='flex flex-col'>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={(values, { resetForm }) => {
          resetPassword(values)
          resetForm()
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('invalid email')
            .required('mandatory field'),
        })}
      >
        {({
          errors,
          values,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit} className='relative ml-7 mt-14'>
              <div className='w-[55%]'>
                <div className='flex justify-start mb-2 col-span-2 '>
                  <RoundedComponent renderNumb='2' />
                  <p className='text-sm text-black ml-3 uppercase'>
                    {t('reset password')}
                  </p>
                </div>
                <p className='text-sm text-black ml-8 mb-3'>
                  {t('type in email to send a password reset link')}
                </p>

                <div className='grid grid-rows-1 ml-8'>
                  <div className='grid grid-cols-2 gap-y-0 gap-x-2 w-full '>
                    <TextFieldWithTitle
                      uppername='E-Mail'
                      placeholder={'max@mustermann.de'}
                      type='text'
                      name='email'
                      value={values.email}
                      className='w-full flex flex-col relative  justify-center h-14 bg-white border-transparent text-sm pl-3 text-zinc-400 shadow-lg rounded-sm'
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      errorMessage={errors.email}
                    />
                  </div>
                  <div className='flex justify-start flex-col mb-5 relative'>
                    <button
                      type='submit'
                      value='rtl'
                      disabled={loading}
                      className={`bg-button-red rounded-md w-1/2 h-12 text-sm text-white focus:outline-none ${
                        loading === false
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed'
                      } ?`}
                      id='reset-button'
                    >
                      {loading ? (
                        <Loader text={t('loading') + '...'} />
                      ) : (
                        <>
                          <CheckCircleRoundedIcon
                            fontSize='large'
                            sx={{
                              color: '#ffffff',
                              marginRight: '10px',
                              padding: '3px',
                            }}
                          />
                          {t('reset password')}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
      {status && message && displayMessage && (
        <p
          className={`ml-14 pl-1 ${
            status === 'success' ? 'text-green' : 'text-red'
          }`}
        >
          {t(message)}
        </p>
      )}
    </div>
  )
}
export default ResetUserPasswordForm
