import React, { useEffect, useState, useCallback } from 'react'
import Avatar from '@mui/material/Avatar'
import { useTranslation } from 'react-i18next'
import api from '../../api'
import { getAuthedUser } from '../../helpers/auth'

const ProfileCard = ({ name, email, lastCheckin }) => {
  const [data, setData] = useState()
  const [error, setError] = useState('')

  const { t } = useTranslation()

  const lastPersonCheckin = useCallback(async () => {
    await api
      .get('visitor/last')
      .then(async (response) => {
        setData(response.data)
      })
      .catch((err) => {
        setError(err.message)
      })
  }, [error])

  useEffect(() => {
    lastPersonCheckin()
  }, [lastPersonCheckin, getAuthedUser])

  return (
    <div className='grid rounded-xl bg-darkBlue h-full my-5 mr-10'>
      <div className='mt-8 rounded-xl flex justify-between items-center bg-gradient-to-br from-[#7a3e65] to-[#e4011c] p-5 pt-12 h-auto'>
        <div className='uppercase text-white text-left'>
          {data && data?.message === 'No visitor in the building was found'
            ? t('no visitor')
            : data?.fullName && (
                <>
                  <h3 className='text-base'>{data.fullName}</h3>
                  <h3 className='text-base '>{data.email}</h3>
                </>
              )}
          <h3 className='text-base font-diodrumSemibold mt-16'>
            {t('last checkin')}
          </h3>
        </div>
        <div className=' mb-10 mr-5 rounded-sm'>
          {data &&
            data?.fullName &&
            (data.profilePhoto ? (
              <img
                alt=''
                src={data.profilePhoto}
                // width={66}
                // className='m-auto h-[51px]  rounded-2xl cover '
                width={60}
                style={{
                  height: '60px',
                  borderRadius: '20px',
                  objectFit: 'cover'
                }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: '#d7d7d7',
                  width: 55,
                  height: 55,
                  borderRadius: '25px'
                }}
                aria-label='recipe'
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ProfileCard
