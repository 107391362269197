import React, { useEffect, useState, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { Avatar } from '@mui/material'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Popup from '../popup'
import usePopupVisible from '../../hooks/usePopupVisible'
import DataTable from '../table'
import api from '../../api'
import { getAuthedUser } from '../../helpers/auth'

const BuildingUsers = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [totalVisitors, setTotalVisitors] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(9)
  const [last12Visitors, setLast12Visitors] = useState([])
  const [error, setError] = React.useState('')

  const { t } = useTranslation()

  const handleOpenPopup = () => {
    setIsPopupVisible(true)
  }

  const navigate = useNavigate()

  const getLast12Visitors = useCallback(async () => {
    await api
      .get(`visitor/in-building?page=0&limit=${rowsPerPage}&statuses=check_in`)
      .then(async (response) => {
        if (response.data) {
          setLast12Visitors(response.data.results)
        }
      })
      .catch((err) => {
        setError(err.message)
        console.log(err)
      })
  }, [last12Visitors, error])

  const getVisitors = useCallback(
    async (page) => {
      await api
        .get(
          `visitor/in-building?page=${page}&limit=${rowsPerPage}&statuses=check_in`
        )
        .then(async (response) => {
          if (response.data) {
            setLoading(false)
            setData(response.data)
            setTotalVisitors(response.data.total)
          }
        })
        .catch((err) => {
          setError(err.message)
          setLoading(false)
        })
    },
    [error]
  )

  useEffect(() => {
    getVisitors(page)
  }, [getVisitors, getAuthedUser])

  useEffect(() => {
    getLast12Visitors()
  }, [data])

  const { ref, isPopupVisible, setIsPopupVisible } = usePopupVisible(false)

  return (
    <>
      <div className='flex flex-col mt-10 mb-5 mr-5'>
        <div className='flex justify-start text-textLightBlue uppercase'>
          <Typography
            variant='h5'
            sx={{ fontSize: '16px', lineHeight: '14px', marginBottom: 1 }}
            paragraph
          >
            {t('currently in building')}
          </Typography>
        </div>
        <div className='grid grid-cols-6 mt-2 gap-1 place-items-left'>
          <div className=''>
            <button onClick={() => navigate('/pre-checkin')}>
              <img
                alt=''
                width={52}
                className='m-auto'
                src='/images/icons/add-visitor.png'
              />
            </button>
          </div>
          {last12Visitors?.map((profile) => (
            <div className=' flex justify-start items-start'>
              {profile.profilePhoto ? (
                <img
                  id='profile-image'
                  alt=''
                  src={profile.profilePhoto}
                  width={55}
                  style={{
                    height: '55px',
                    borderRadius: '20px',
                    objectFit: 'cover'
                  }}
                />
              ) : (
                <div className=' flex justify-start items-start'>
                  <Avatar
                    sx={{
                      bgcolor: '#d7d7d7',
                      width: 55,
                      height: 55,
                      borderRadius: '20px',
                      margin: 'auto'
                    }}
                    aria-label='recipe'
                  />
                </div>
              )}
            </div>
          ))}
          {totalVisitors && totalVisitors > 0 ? (
            <div className='items-center justify-center'>
              <button
                type='button'
                className='w-[90px] mt-3'
                onClick={handleOpenPopup}
              >
                <a className='text-textLightBlue hover:underline text-sm flex gap-x-2 items-center inline inline-flex'>
                  <p>{t('view list')}</p>
                  <ArrowForwardIcon sx={{ width: '15px', height: '15px' }} />
                </a>
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
        {last12Visitors && last12Visitors.length === 0 ? (
          <p className='relative bottom-11 left-14'>{t('no data')}</p>
        ) : (
          ''
        )}
      </div>
      <Popup
        open={isPopupVisible}
        handleClose={handleOpenPopup}
        position='fixed left-0 bottom-0 top-auto'
        backgroundColor='bg-black'
        fullWidthOverlay={true}
      >
        <div ref={ref} className='relative max-h-[95vh] w-[60vw]'>
          <div className='px-5 bg-white rounded-2xl'>
            <span
              className='absolute right-5 top-4 cursor-pointer'
              onClick={() => setIsPopupVisible(false)}
            >
              <img width={15} src='/images/icons/close-icon.png' />
            </span>

            <DataTable
              hasSort={true}
              hasFilter={false}
              hasPagination={true}
              headCells={[
                {
                  left: true,
                  disableSorting: true,
                  id: 'name',
                  label:
                    t('there are') +
                    ' ' +
                    totalVisitors +
                    ' ' +
                    t('visitors in the building'),
                  className: `uppercase ${
                    data?.results?.length > 0 ? '-ml-20' : '-ml-16'
                  }`
                },
                {
                  id: 'type',
                  numeric: false,
                  disablePadding: false,
                  label: t('type')
                },
                {
                  id: 'checkInStartDate',
                  numeric: true,
                  disablePadding: false,
                  label: t('check in/out period')
                },
                {
                  id: 'status',
                  numeric: false,
                  disablePadding: false,
                  label: 'Status'
                }
              ]}
              rows={data?.results}
              totalCounts={totalVisitors}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              getData={getVisitors}
              dataType='read-only'
            />
          </div>
        </div>
      </Popup>
    </>
  )
}
export default BuildingUsers
