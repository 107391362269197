import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import Sidebar from './sidebar'
import Topbar from './topbar'
import { withTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { usePrevious } from '../hooks/usePrevious'
import store from '../store'
import { setSearchQuery } from '../store/slices/search-slice'
import { getAuthedUser, parseJwt, unAuthUser } from '../helpers/auth'
import { setAuthorizationHeader } from '../api'
import Cookie from '../components/cookie'

const Page = ({ pageName, textColor, children }) => {
	const [sidebarOpen, setSidebarOpen] = React.useState(true)
	const drawerWidth = 240

	const { searchQuery } = useSelector((state) => state.search)

	const navigate = useNavigate()

	const handleSidebarToggle = () => {
		setSidebarOpen(!sidebarOpen)
	}

	const router = useLocation().pathname.replace('/', '')
	const prevAmount = usePrevious({ router })

	useEffect(() => {
		if (prevAmount?.router !== router) {
			store.dispatch(setSearchQuery(''))
		}
	}, [router, searchQuery])

	const userToken = getAuthedUser()
	const user = userToken && parseJwt(userToken)

	useEffect(() => {
		if (userToken) {
			setAuthorizationHeader(userToken)
		}
	}, [userToken])

	if (user && new Date(user.exp * 1000) < new Date()) {
		unAuthUser()
		navigate('/login')
	}

	return (
		<Box textColor='text-center text-3xl' sx={{ display: 'flex' }}>
			<Sidebar
				isOpen={sidebarOpen}
				handleSidebarToggle={handleSidebarToggle}
				userRole={user?.role}
			/>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					p: 3,
					width: sidebarOpen ? { sm: `calc(100% - ${drawerWidth}px)` } : '100%',
					overflow: 'hidden',
					position: 'relative',
				}}
				className='leftside'
			>
				<Topbar
					pageName={pageName}
					textColor={textColor}
					userRole={user?.role}
				/>
				{children}
				<Cookie
					position='fixed'
					width='w-3/5'
					marginBottom='bottom-10'
					boxShadow='shadow-2xl	'
				/>
			</Box>
		</Box>
	)
}

export default withTranslation()(Page)
