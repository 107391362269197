import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { ExpandMore } from '@mui/icons-material'

export default function TableHeader(props) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead
      sx={
        props.popupOpen
          ? {
              position: 'relative',
              zIndex: '99',
              backgroundColor: '#fff'
            }
          : {}
      }
    >
      <TableRow>
        <TableCell padding='checkbox'></TableCell>
        {props.headCells?.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.left ? 'left' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.filterName ? order : false}
              className={headCell.left ? 'text-left force-left' : 'text-center'}
            >
              {headCell.disableSorting ? (
                <span className={headCell.className}>{headCell.label}</span>
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.filterName}
                  direction={orderBy === headCell.filterName ? order : 'asc'}
                  onClick={createSortHandler(headCell.filterName)}
                  sx={{ marginRight: '-15px' }}
                >
                  <span
                    className={`text-base text-textLightBlue font-diodrumMedium uppercase ${headCell.className}`}
                  >
                    {headCell.label}
                    {orderBy === headCell.filterName ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'down'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </span>
                </TableSortLabel>
              )}
            </TableCell>
          )
        })}
        {props.hasFilter && (
          <TableCell
            sx={{
              display: 'flex',
              justifyContent: 'right'
            }}
          >
            <button
              className='flex justify-between'
              onClick={(e) => {
                e.stopPropagation()
                props.handleClosePopup()
              }}
            >
              <span
                className={`text-base text-textLightBlue uppercase font-${
                  props.popupOpen ? 'semibold' : 'normal'
                } `}
              >
                Filter
              </span>
              <ExpandMore sx={{ color: '#9191af' }} />
            </button>
          </TableCell>
        )}
        {props.table === 'check-ins' && <TableCell />}
      </TableRow>
    </TableHead>
  )
}

TableHeader.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}
