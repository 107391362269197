import React, { useCallback, useEffect, useState } from 'react'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { useTranslation } from 'react-i18next'
import RoundedComponent from './rounded'
import api from '../../api'
import QuestionnaireFormRow from '../forms/questionnaireFormRow'
import { getAuthedUser } from '../../helpers/auth'
import Loader from '../loader'

export default function QuestionnareCard({ questionCatalog }) {
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [questions, setQuestions] = useState()
  const [addQuestion, setAddQuestion] = useState(false)
  const [error, setError] = React.useState('')
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const addNewQuestion = () => {
    setAddQuestion(!addQuestion)
  }
  const getQuestions = useCallback(async () => {
    setLoading(true)
    await api
      .get(
        `/question?page=0&limit=100&language=${i18n.language}&questionCatalog=${questionCatalog}`
      )
      .then(async (response) => {
        if (response.data) {
          setQuestions(response.data.results)
          setRowsPerPage(response.data.total)
          setLoading(false)
        }
      })
      .catch((err) => {
        setError(err.message)
        setLoading(false)
        console.log(err)
      })
  }, [i18n.language, error, questionCatalog])

  useEffect(() => {
    getQuestions()
  }, [getQuestions, getAuthedUser])

  useEffect(() => {
    setAddQuestion(false)
  }, [i18n.language, questionCatalog])

  return (
    <div className='w-full bg-white py-6 px-5 '>
      <div className='grid grid-cols-5 mx-3 h-12 '>
        <div className='grid col-span-2 border-r border-1 border-gray'>
          <div className='flex flex-start'>
            <RoundedComponent renderNumb={'a'} />
            <p className='text-sm text-black ml-2 uppercase'>
              {t('catalog of questions')}
            </p>
          </div>
          <div className='row-span-1 pt-4'>
            <p className='text-xs font-bold '>{t('questions')}</p>
          </div>
        </div>

        <div className='grid col-span-2 border-r border-1 border-gray'>
          <div className='flex flex-start h-5 ml-3 pr-2'>
            <RoundedComponent renderNumb={'b'} />
            <p className='text-sm text-black ml-2 uppercase'>{t('answers')}</p>
          </div>
          <div className='row-span-1 pt-4 pl-5'>
            <p className='text-xs font-bold capitalize'>{t('answers')}</p>
          </div>
        </div>

        <div className='grid col-span-1 '>
          <div className='flex flex-start h-5 ml-3'>
            <RoundedComponent renderNumb={'c'} />
            <p className='text-sm text-black ml-2 uppercase'>
              {t('correct answers')}
            </p>
          </div>
          <div className='row-span-1 pt-4 pl-5 capitalize'>
            <p className='text-xs font-bold '>{t('correct answers')}</p>
          </div>
        </div>
      </div>
      {loading && (
        <div className='w-full text-center my-4 pt-2 pb-1'>
          <Loader width='10' height='10' />
        </div>
      )}
      <div className='mx-4'>
        {questions &&
          questions?.map((data, index) => (
            <QuestionnaireFormRow
              getQuestions={getQuestions}
              id={data.id}
              question={data.question}
              answer1={data.answerA}
              answer2={data.answerB}
              answer3={data.answerC}
              correctAnswer={data.correctAnswer}
              index={index}
            />
          ))}
        {addQuestion && (
          <QuestionnaireFormRow
            getQuestions={getQuestions}
            question={''}
            answer1={''}
            answer2={''}
            answer3={''}
            correctAnswer={''}
            addNewQuestion={addNewQuestion}
            questionCatalog={questionCatalog}
          />
        )}
        <button
          className='flex justify-between items-center mt-8 bg-buttonGray rounded px-2'
          title={t('new question')}
          type='button'
          onClick={addNewQuestion}
        >
          {/* <img
            className=''
            width={30}
            src='/images/icons/topbar/add-user-icon.png'
          /> */}
          <AddCircleOutlineRoundedIcon sx={{ color: 'white', width: '15px' }} />
          <span className='text-white ml-1 text-xs'>{t('new question')}</span>
        </button>
      </div>
    </div>
  )
}
