import { Avatar } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { formatDate, getType } from '../helpers'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

export default function VisitorCard({
  type,
  fullName,
  companyName,
  contactPerson,
  profilePhoto,
  checkInStartDate,
  checkInEndDate,
}) {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const now = new Date()

  const getDate = () => {
    let date
    if (checkInStartDate) date = formatDate(checkInStartDate)
    if (checkInEndDate) date += ' - ' + formatDate(checkInEndDate)
    if (!checkInStartDate) date = formatDate(now)
    return date
  }

  const { t } = useTranslation()

  useEffect(() => {
    handlePrint()
  }, [])

  return (
    <div className='hidden' id='visitCard'>
      <div
        ref={componentRef}
        className='flex flex-col justify-center items-center px-4 h-full w-full mx-auto'
        id='print'
      >
        <div className=' border-darkGray border-8'>
          <div className='bg-white flex flex-row justify-between items-center border-b-8 border-red'>
            <div className='mx-5'>
              <h1 className='font-diodrumMedium text-2xl text-neutral-800'>
                {getType(type, t)}
              </h1>
            </div>
            <div className='m-2'>
              <img
                src='/images/dupont-logo.png'
                alt='image'
                loading='lazy'
                className='w-28 h-10'
              />
            </div>
          </div>
          <div className='bg-white'>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-col justify-between mx-5 my-2'>
                <div>
                  <p className=' text-xs text-neutral-600 font- capitalize'>
                    {t('first name') + ' ' + t('last name')}
                  </p>
                  <h1 className='font-diodrumMedium text-2xl text-neutral-800'>
                    {fullName}
                  </h1>
                </div>
                {companyName ? (
                  <div>
                    <p className=' text-xs text-neutral-600 font-diodrumMedium'>
                      {t('company')}
                    </p>
                    <h1 className='font-diodrumMedium text-2xl text-neutral-800'>
                      {companyName}
                    </h1>
                  </div>
                ) : (
                  ''
                )}

                {contactPerson ? (
                  <div>
                    <p className=' text-xs text-neutral-600 font-diodrumMedium'>
                      {t('contact person')}
                    </p>
                    <h1 className='font-diodrumMedium text-2xl text-neutral-800'>
                      {contactPerson}
                    </h1>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className='p-3'>
                {profilePhoto !== null ? (
                  <img
                    id='profile-image-table'
                    src={profilePhoto}
                    alt='image'
                    loading='lazy'
                    className='h-40 w-32 cover mx-5 mt-5'
                  />
                ) : (
                  <Avatar
                    sx={{
                      background: 'white',
                      width: 110,
                      height: 130,
                      borderRadius: 0,
                      marginTop: 2,
                      marginLeft: 2,
                      marginRight: 2,
                      color: '#a8a29e',
                    }}
                    aria-label='recipe'
                  />
                )}
                <div className='flex justify-end'>
                  <p className='justify-right px-5'>{getDate()}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
