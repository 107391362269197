import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../api'
import Page from '../layout'
import AddUserForm from '../components/forms/add-user'
import ResetUserPasswordForm from '../components/forms/reset-user-password'
import UserList from '../components/users-list'

export default function AddUser() {
  const [administratorUsers, setAdministratorsUsers] = useState([])
  const [subscribersUsers, setSubscriberssUsers] = useState([])
  const [teamAssistantUsers, setTeamAssistantsUsers] = useState([])
  const { t } = useTranslation()

  const getAdministratorsUsers = async () => {
    await api
      .get('user?role=administrator')
      .then(async (response) => {
        if (response.data) {
          setAdministratorsUsers(response.data.results)
        }
      })
      .catch((err) => {})
  }

  const getSubscriberssUsers = async () => {
    await api
      .get('user?role=subscriber')
      .then(async (response) => {
        if (response.data) {
          setSubscriberssUsers(response.data.results)
        }
      })
      .catch((err) => {})
  }

  const getTeamAssistantsUser = async () => {
    await api
      .get('user?role=team_assistant')
      .then(async (response) => {
        if (response.data) {
          setTeamAssistantsUsers(response.data.results)
        }
      })
      .catch((err) => {})
  }

  const getUsers = () => {
    getTeamAssistantsUser()
    getAdministratorsUsers()
    getSubscriberssUsers()
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <Page pageName={t('add user')}>
      <div className='h-auto'>
        <AddUserForm getUsers={getUsers} />
        <ResetUserPasswordForm />
        <UserList
          administratorUsers={administratorUsers}
          subscribersUsers={subscribersUsers}
          teamAssistantUsers={teamAssistantUsers}
        />
      </div>
    </Page>
  )
}
