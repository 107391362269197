import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import Dashboard from '../views/dashboard'
import Login from '../views/login'
import Confirmation from '../views/confirmation'
import Checkins from '../views/checkins'
import Checkouts from '../views/checkouts'
import History from '../views/history'
import PreCheckin from '../views/pre-checkin'
import AddUser from '../views/add-user'
import Settings from '../views/settings'
import SOS from '../views/sos'
import PrivacyPolicy from '../views/privacy-policy'
import AuthGuard from './auth-gard'
import Activities from '../views/activities'
import ResetPassword from '../views/reset-password'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthGuard permissions={['administrator', 'subscriber']}>
        <Dashboard />
      </AuthGuard>
    ),
    errorElement: <h1>error page</h1>,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/confirmation',
    element: <Confirmation />,
  },
  {
    path: '/checkins',
    element: (
      <AuthGuard permissions={['administrator', 'subscriber']}>
        <Checkins />
      </AuthGuard>
    ),
  },
  {
    path: '/checkouts',
    element: (
      <AuthGuard permissions={['administrator', 'subscriber']}>
        <Checkouts />
      </AuthGuard>
    ),
  },
  {
    path: '/history',
    element: (
      <AuthGuard permissions={['administrator']}>
        <History />
      </AuthGuard>
    ),
  },

  {
    path: '/pre-checkin',
    element: (
      <AuthGuard
        permissions={['administrator', 'subscriber', 'team_assistant']}
      >
        <PreCheckin />
      </AuthGuard>
    ),
  },
  {
    path: '/add-user',
    element: (
      <AuthGuard permissions={['administrator']}>
        <AddUser />
      </AuthGuard>
    ),
  },
  {
    path: '/settings',
    element: (
      <AuthGuard permissions={['administrator']}>
        <Settings />
      </AuthGuard>
    ),
  },
  {
    path: '/sos',
    element: (
      <AuthGuard permissions={['administrator', 'subscriber']}>
        <SOS />
      </AuthGuard>
    ),
  },
  {
    path: '/activities',
    element: (
      <AuthGuard permissions={['administrator', 'subscriber']}>
        <Activities />
      </AuthGuard>
    ),
  },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
])

export default router
