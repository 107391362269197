import * as React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import api from '../../api'
import { formatDate, formatTime, getStatusLabel } from '../../helpers'
import { getAuthedUser } from '../../helpers/auth'

export default function Activities() {
  const [data, setData] = React.useState({})
  const [error, setError] = React.useState('')

  const { searchQuery } = useSelector((state) => state.search)

  const { t, i18n } = useTranslation()

  const checkiInDate = (date) => {
    const dataToReturn = formatDate(date)
    if (date === null) {
      return null
    } else {
      return dataToReturn
    }
  }
  const checkinTime = (date) => {
    const timeToReturn = formatTime(date)

    if (date === null) {
      return null
    } else {
      return timeToReturn
    }
  }

  const getData = React.useCallback(async () => {
    try {
      const response = await api.get(
        `visitor?limit=5&page=0&visitorHistory=false&search=${searchQuery}`
      )
      setData(response.data)
    } catch (err) {
      setError(err.message)
      console.log(err)
    }
  }, [searchQuery, error])

  React.useEffect(() => {
    getData()
  }, [getData, getAuthedUser])

  const CardContentRow = ({
    id,
    profileImg,
    name,
    status,
    checkInDate,
    checkInTime
  }) => (
    <Box className='flex' marginBottom={3}>
      {profileImg ? (
        <img
          id='profile-image'
          alt=''
          width={40}
          src={profileImg}
          className='m-auto h-[40px] rounded-2xl cover'
        />
      ) : (
        <Avatar
          sx={{
            bgcolor: '#d7d7d7',
            width: 40,
            height: 40,
            borderRadius: '15px'
          }}
          aria-label='recipe'
        />
      )}
      <Box marginLeft={3} marginTop={0.5} className='w-full text-left'>
        <Typography
          variant='h5'
          mb={0}
          sx={{
            fontSize: '14px',
            lineHeight: '14px',
            color: '#565682',
            fontWeight: '500'
          }}
          paragraph
        >
          {i18n.language === 'de' && status === t('waiting for approval') ? (
            <span>{name}</span>
          ) : (
            <span>
              {name} {t('is')}
            </span>
          )}
          <span className='ml-1'>{status}</span>
        </Typography>
        <Typography
          variant='p'
          mb={0}
          sx={{
            fontSize: '12px',
            lineHeight: '14px',
            color: status === 'Checked out' ? '#0fb269' : '#212145',
            fontWeight: '500',
            textTransform: 'none'
          }}
          className='inline-block capitalize-first-letter'
        >
          {status}
        </Typography>
        <Typography
          variant='p'
          mb={0}
          ml={2}
          sx={{
            fontSize: '12px',
            lineHeight: '12px'
          }}
          className='inline-block text-gray'
        >
          {checkInDate + ' ' + t('at') + ' ' + checkInTime + ' ' + t('clock')}
        </Typography>
      </Box>
    </Box>
  )

  return (
    <Card
      sx={{
        maxWidth: '100%',
        borderRadius: '20px',
        marginTop: '20px',
        padding: '20px',
        paddingTop: 0
      }}
    >
      <CardHeader
        title={t('activities')}
        titleTypographyProps={{ variant: 'p' }}
        sx={{
          textAlign: 'left',
          textTransform: 'uppercase',
          color: '#9191af',
          paddingX: 0,
          fontWeight: 'normal',
          fontSize: '15px'
        }}
      />

      <CardContent>
        {data.results?.map((item, index) => (
          <CardContentRow
            key={index}
            id={item.id}
            profileImg={item.profilePhoto}
            name={item.fullName}
            status={getStatusLabel(item.status, t)}
            checkInTime={
              item.checkInStartDate !== null
                ? checkinTime(item.checkInStartDate)
                : item.checkOutDate
                ? checkinTime(item.checkOutDate)
                : checkinTime(item.createdAt)
            }
            checkInDate={
              item.checkInStartDate !== null
                ? checkiInDate(item.checkInStartDate)
                : item.checkOutDate
                ? checkiInDate(item.checkOutDate)
                : checkiInDate(item.createdAt)
            }
          />
        ))}
        {data.results && data.results.length === 0 && <p>{t('no data')}</p>}
      </CardContent>

      <Link to='/activities'>
        <a className='block text-sm text-textDarkBlue font-diodrumSemibold rounded-xl bg-lightGray py-1 text-center'>
          {t('load more')}
        </a>
      </Link>
    </Card>
  )
}
