import { createSlice } from '@reduxjs/toolkit'

const sosSlice = createSlice({
  name: 'sos',
  initialState: {
    sosButtonClicked: false
  },
  reducers: {
    setSosButtonClicked(state, action) {
      state.sosButtonClicked = action.payload
    }
  }
})

const { actions, reducer } = sosSlice

export const { setSosButtonClicked } = actions

export default reducer
