import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DataTable from '../components/table'
import Page from '../layout'
import api from '../api/index'
import {
  formatDate,
  formatTimeToISOString,
  isValidTimeFormat
} from '../helpers'
import { useTranslation } from 'react-i18next'

export default function Checkouts() {
  const [visitors, setVisitors] = useState([])
  const [totalVisitors, setTotalVisitors] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(9)
  const [dateRange, setDateRange] = useState()
  const [filterName, setFilterName] = useState('')
  const [filterDirection, setFilterDirection] = useState('')

  const { t } = useTranslation()

  const startDate = formatDate(dateRange?.startDate, true, true) || ''
  const endDate = formatDate(dateRange?.endDate, true, true) || ''

  const { searchQuery } = useSelector((state) => state.search)

  const headCells = [
    {
      filterName: 'visitor',
      filterDirection: filterDirection,
      id: 'fullName',
      numeric: false,
      left: true,
      disablePadding: true,
      label: `${t('visitor')} (${totalVisitors})`,
      className: visitors?.length > 0 ? '-ml-16 pl-1' : '-ml-8'
    },
    {
      filterName: 'type',
      filterDirection: filterDirection,
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: t('type')
    },
    {
      filterName: 'check_in_period',
      filterDirection: filterDirection,
      id: 'checkInStartDate',
      numeric: true,
      disablePadding: false,
      label: t('check in/out period')
    },
    {
      filterName: 'status',
      filterDirection: filterDirection,
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status'
    }
  ]

  const getVisitors = useCallback(
    async (page) => {
      const searchTime = isValidTimeFormat(searchQuery)
        ? formatTimeToISOString(searchQuery)
        : searchQuery

      await api
        .get(
          `visitor?visitorHistory=false&statuses=check_out&page=${page}&limit=${rowsPerPage}${
            startDate && `&startDate=${startDate}`
          }${
            endDate && `&endDate=${endDate}`
          }&search=${searchTime}&filterName=${filterName}&filterDirection=${filterDirection}`
        )
        .then(async (response) => {
          if (response.data) {
            setVisitors(response.data.results)
            setTotalVisitors(response.data.total)
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    [startDate, endDate, searchQuery, filterName, filterDirection]
  )

  useEffect(() => {
    getVisitors(page)
  }, [getVisitors, , filterDirection, filterName])

  return (
    <Page pageName={'Check-Outs'}>
      <div className='h-auto min-h-screen'>
        <DataTable
          table='checkout'
          dataType='edit'
          hasSort={true}
          hasFilter={true}
          hasPagination={true}
          headCells={headCells}
          totalCounts={totalVisitors}
          rows={visitors}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          filterName={filterName}
          setFilterName={setFilterName}
          filterDirection={filterDirection}
          setFilterDirection={setFilterDirection}
          setRowsPerPage={setRowsPerPage}
          getData={getVisitors}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        {visitors && visitors.length === 0 && (
          <p className='block p-1 pl-2 m-3 bg-lightGray'>{t('no data')}</p>
        )}
      </div>
    </Page>
  )
}
