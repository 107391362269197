import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import isEmail from 'validator/lib/isEmail'
import { Link, useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useTranslation } from 'react-i18next'
import UnAuthLayout from '../layout/unAuthLayout'
import UnAuthLayoutTopbar from '../layout/unAuthTopbar'
import TextFieldComponent from '../components/forms/form-components/text-field'
import { authUser, parseJwt } from '../helpers/auth'
import api, { setAuthorizationHeader } from '../api'
import Loader from '../components/loader'
import Cookie from '../components/cookie'

const LoginContent = () => {
  const [error, setError] = useState('')
  const [openText, setOpenText] = useState(false)
  const [loading, setLoading] = useState(false)
  const [forgetPassword, setForgetPassword] = useState(false)

  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleOpenText = () => {
    setOpenText(!openText)
  }
  const handleForgetPassword = () => {
    setForgetPassword(true)
  }

  const loginfuncition = async (values, formikHelpers) => {
    setLoading(true)

    try {
      const response = await api.post('user/login', {
        email: values.email,
        password: values.password,
      })
      if (response.data) {
        authUser(response.data)
        setAuthorizationHeader(response.data)
        setLoading(false)
        const user = response.data && parseJwt(response.data)

        if (user?.role === 'team_assistant') return navigate('/pre-checkin')
        return navigate('/')
      }
    } catch (err) {
      setLoading(false)
      if (err.code === 'ERR_NETWORK') {
        setError('smth went wrong')
      } else if (err.response.data.code === 'invalid_credentials') {
        setError('wrong-password')
      } else if (err.response.data.code === 'resource_not_found') {
        setError('email-not-exist')
      }
    }
  }

  return (
    <div className='flex flex-col justify-between overflow-y-hidden p-12 bg-white relative'>
      <UnAuthLayoutTopbar />
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values, formikHelpers) => {
          loginfuncition(values, formikHelpers)
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('invalid email')
            .test((value) => isEmail(value))
            .required('mandatory field'),
          password: Yup.string()
            .min(8, 'password required')
            .required('mandatory field')
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
              'required characters'
            ),
        })}
      >
        {({
          errors,
          values,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <div className='w-full md:w-2/3 m-auto'>
            <p className='text-sm uppercase text-textLightBlue'>
              {t('welcome back')}
            </p>
            <h4 className='font-diodrumSemibold text-2xl text-black mt-2 mb-4'>
              {t('login')}
            </h4>
            <form onSubmit={handleSubmit}>
              <TextFieldComponent
                uppername='E-Mail'
                startIcon='/images/icons/email-icon.png'
                placeholder={t('your email')}
                type='text'
                name='email'
                value={values.email}
                className='w-full flex justify-start h-13 rounded-3xl border border-slate-300 text-md pl-3'
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                errorMessage={errors.email}
              />

              <TextFieldComponent
                uppername={t('password')}
                startIcon='/images/icons/key-icon.png'
                endIcon={
                  <VisibilityIcon
                    sx={{ color: '#dfe2e6' }}
                    onClick={handleOpenText}
                  />
                }
                placeholder={t('your password')}
                type={openText ? 'text' : 'password'}
                name='password'
                value={values.password}
                className='w-full h-13 flex justify-start rounded-3xl border border-slate-300 text-md pl-3'
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                errorMessage={errors.password}
              />
              <h1
                className='mb-6 ml-3 underline text-textGray text-md cursor-pointer pt-[5px]'
                onClick={handleForgetPassword}
              >
                {forgetPassword
                  ? t('contact admin to reset password')
                  : t('forget password')}
              </h1>
              <button
                type='submit'
                value='rtl'
                className='bg-button-red hover:bg-red rounded-3xl w-2/4 h-12 text-lg text-white mt-3 inline-flex justify-center items-center'
              >
                {loading ? <Loader text={t('loading') + '...'} /> : 'Login'}
              </button>
              <div className='mt-1 mb-1 text-red text-xs h-4 '>
                <p error>{t(error)}</p>
              </div>
            </form>
          </div>
        )}
      </Formik>
      <div className='w-auto'>
        <Link to='/privacy-policy' title={t('privacy tooltip')}>
          <a className='text-sm font-bold p-1 capitalize'>
            {t('privacy policy')}
          </a>
        </Link>
      </div>
      <Cookie
        position='absolute'
        width='w-full'
        marginBottom='bottom-0'
        boxShadow='shadow-none'
      />
    </div>
  )
}

const Login = () => {
  return (
    <UnAuthLayout>
      <LoginContent />
    </UnAuthLayout>
  )
}
export default Login
