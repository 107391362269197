import { createSlice } from '@reduxjs/toolkit'

const checkinSlice = createSlice({
  name: 'checkin',
  initialState: {
    testPassed: false,
    testNotPassed: false,
    applyFilter: false
  },
  reducers: {
    setTestPassed(state, action) {
      state.testPassed = action.payload
    },
    setTestNotPassed(state, action) {
      state.testNotPassed = action.payload
    },
    setApplyFilter(state, action) {
      state.applyFilter = action.payload
    }
  }
})

const { actions, reducer } = checkinSlice

export const { setTestPassed, setTestNotPassed, setApplyFilter } = actions

export default reducer
