import React from 'react'
import { TextField } from '@mui/material'
import Search from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import { setSearchQuery } from '../store/slices/search-slice'
import store from '../store'
import { useTranslation } from 'react-i18next'

const SearchComponent = ({
  searchQuery,
  position,
  handleClose,
  paddingBottom
}) => {
  const { t } = useTranslation()
  return (
    <section
      className={`absolute ${
        position || 'top-28'
      } left-auto w-full modal__bg z-50 flex justify-end`}
    >
      <div
        className={`h-full bg-white rounded-xl p-7 pb-${paddingBottom} pr-10`}
      >
        {/* <form className='w-full h-16  flex justify-center items-center  '> */}
        <TextField
          id='search-bar'
          className='search-input bg-lightGray'
          onInput={(e) => {
            store.dispatch(setSearchQuery(e.target.value))
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') handleClose()
          }}
          value={searchQuery || ''}
          label={t('search by') + ' ...'}
          variant='outlined'
          placeholder={t('search') + ' ...'}
          size='small'
          InputProps={{
            endAdornment: (
              <IconButton
                type='submit'
                aria-label='search'
                className='ml-10'
                style={{ marginLeft: '35px' }}
              >
                <Search
                  sx={{
                    color: '#c6cfe4',
                    fontSize: '18px'
                  }}
                />
              </IconButton>
            )
          }}
          InputLabelProps={{
            style: {
              fontSize: 14,
              color: '#c6cfe4'
            }
          }}
        />
        {/* </form> */}
      </div>
    </section>
  )
}
export default SearchComponent
