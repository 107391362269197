import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export default function Cookie({ position, width, marginBottom, boxShadow }) {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    const hasClickedCloseButton = localStorage.getItem('cookieClosed')
    if (hasClickedCloseButton) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [])

  const handleCookieClose = () => {
    setOpen(false)
    localStorage.setItem('cookieClosed', 'true')
  }

  return (
    <>
      {open && (
        <div
          className={`bg-cookieBg ${position} ${marginBottom} right-0 left-0 mx-auto ${width} z-50 ${boxShadow}`}
        >
          <div className='flex flex-col md:flex-row md:justify-between gap-x-10 px-8 py-3 md:align-middle items-center mt-2'>
            <div className='flex flex-col text-center md:text-left'>
              <a
                href='/privacy-policy'
                className='font-semibold text-black text-lg pb-2 hover:underline'
              >
                {t('cookie header')}
              </a>
              <p className='text-md text-textLightBlue'>
                {t('cookie description')}
              </p>
            </div>

            <button
              type='button'
              data-cc='accept-all'
              onClick={handleCookieClose}
              className='bg-black text-white text-lg font-medium py-2 px-10 rounded-full'
            >
              {t('cookie button')}
            </button>
          </div>
        </div>
      )}
    </>
  )
}
