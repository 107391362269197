import React, { useState, useEffect, useCallback, useDebugValue } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import DataTable from '../components/table'
import Page from '../layout'
import api from '../api'

export default function SOS() {
  const [visitors, setVisitors] = useState([])
  const [totalVisitors, setTotalVisitors] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100000)
  const [count, setCount] = useState(0)

  const { t } = useTranslation()

  const { searchQuery } = useSelector((state) => state.search)
  const { sosButtonClicked } = useSelector((state) => state.sos)

  const getVisitors = useCallback(
    async (page) => {
      await api
        .get(
          `visitor/in-building?page=${page}&limit=${rowsPerPage}&search=${searchQuery}&statuses=check_in,draft_check_in`
        )
        .then(async (response) => {
          if (response.data) {
            setVisitors(response.data.results)
            setTotalVisitors(response.data.total)
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    [searchQuery]
  )

  useEffect(() => {
    getVisitors(page)
  }, [getVisitors])

  const print = async () => {
    window.print().then(() => {
      setCount(count + 1)
    })
  }

  useEffect(() => {
    setCount(count + 1)
    if (sosButtonClicked && visitors.length && count === 1) {
      print()
    }
  }, [visitors])

  return (
    <Page pageName={'SOS'} textColor='text-red'>
      <div className='h-auto min-h-screen' id='sos-page'>
        <DataTable
          hasSort={false}
          hasFilter={false}
          hasPagination={false}
          headCells={[
            {
              left: true,
              disableSorting: true,
              id: 'name',
              label:
                visitors?.length > 1
                  ? t('there are') +
                    ' ' +
                    totalVisitors +
                    ' ' +
                    t('visitors in the building')
                  : t('there is') +
                    ' ' +
                    totalVisitors +
                    ' ' +
                    t('visitor in the building'),
              className: `text-base text-red font-bold uppercase ${
                visitors?.length > 0 ? '-ml-20 pl-1' : '-ml-11'
              }`
            },
            {
              filterName: 'contact-person',
              id: 'contact-person',
              numeric: false,
              disablePadding: true,
              disableSorting: true,
              label: t('contact person'),
              className: `text-base text-red font-bold uppercase block ${
                visitors?.length == 0 ? 'text-right mr-10' : 'text-center'
              }`
            }
          ]}
          rows={visitors}
          printOption={{ label: t('print out the visitor list') }}
          totalCounts={totalVisitors}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          getData={getVisitors}
          dataType='read-only'
          table='sos'
        />
        {visitors && visitors.length === 0 && (
          <p className='block p-1 pl-2 m-3 bg-lightGray'>{t('no data')}</p>
        )}
      </div>
    </Page>
  )
}
