import { Field } from 'formik'
import { useState } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

const QuestionField = ({
  numb,
  name,
  type,
  placeholder,
  onChange,
  onClick,
  value,
  handleChange,
  touched,
  error,
  helperText,
  errorMessage
}) => {
  const { t } = useTranslation()
  const formikProps = useFormikContext()
  const setValue = () => {
    formikProps.setFieldValue(`${name}`, '')
  }
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div
      className='flex flex-row justify-center items-center mr-4 relative'
      id='input'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseLeave}
    >
      <p>{numb ? numb + '.' : ''}</p>
      <Field
        id='tooltip'
        className={`border-none outline-none flex justify-center items-start text-left text-xs pl-1 relative w-full whitespace-nowrap overflow-hidden text-ellipsis`}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onClick={onClick}
        handleChange={handleChange}
      />
      {isHovered && value && (
        <span className='absolute bg-buttonGray text-black text-xs px-2 py-1 rounded bottom-7 z-10'>
          {value}
          <div className='triangle'></div>
        </span>
      )}
      <button type='button' onClick={setValue}>
        <img width={18} src='images/icons/zurucksetzen.png' />
      </button>
      {touched ||
        (error && (
          <div className='absolute left-0 top-5 mt-1.5'>
            <span className='block'>
              <p className='text-[11px] text-red ml-1 text-errorBorder '>
                {t(errorMessage)}
              </p>
            </span>
          </div>
        ))}
    </div>
  )
}

export default QuestionField
