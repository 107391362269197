import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'
import { useTranslation } from 'react-i18next'
import VisibilityIcon from '@mui/icons-material/Visibility'
import UnAuthLayout from '../layout/unAuthLayout'
import UnAuthLayoutTopbar from '../layout/unAuthTopbar'
import TextFieldComponent from '../components/forms/form-components/text-field'
import api from '../api'
import Loader from '../components/loader'
import Cookie from '../components/cookie'

const ResetPasswordContent = () => {
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [openText, setOpenText] = useState(false)
  const [loading, setLoading] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const queryParamas = location?.search || ''
  const queryParamas2 = queryParamas.split('?').filter((x) => x != '')
  const queryParamasEmail = queryParamas2[0].split('=')
  const queryParamasCode = queryParamas2[1].split('=')

  const emailKey = queryParamasEmail[0]
  const emailVal = queryParamasEmail[1]
  const codeKey = queryParamasCode[0]
  const codeVal = queryParamasCode[1]

  const obj = {
    [emailKey]: emailVal,
    [codeKey]: codeVal,
  }

  const { t } = useTranslation()

  const handleOpenText = () => {
    setOpenText(!openText)
  }
  const handleResetPassword = () => {
    setResetPassword(true)
  }

  const onReset = async (values, formikHelpers) => {
    setLoading(true)

    try {
      const response = await api.post('user/change-password', {
        ...obj,
        newPassword: values.password,
      })
      if (response.data) {
        handleResetPassword()
        setMessage('new password successfully set up')
        setStatus('success')
        setLoading(false)

        setTimeout(() => {
          navigate('/login')
        }, 2500)
      }
    } catch (err) {
      setStatus('error')
      handleResetPassword()
      setLoading(false)
      if (
        err &&
        err.response.data.code === 'validation_error' &&
        err.response.data.message ===
          'New Password must be between 8 and 50 characters'
      ) {
        setMessage('description.validation_error')
      } else if (err.response.data.code === 'expired_token') {
        setMessage('description.expired_token')
      } else if (err.response.data.code === 'invalid_password_reset_token') {
        setMessage('description.invalid_password_reset_token')
      } else setMessage('user not found')
    }
  }

  return (
    <div className='flex flex-col justify-between overflow-y-hidden p-12 bg-white relative'>
      <UnAuthLayoutTopbar />
      <Formik
        enableReinitialize
        initialValues={{
          email: obj.email || '',
          password: '',
        }}
        onSubmit={(values, formikHelpers) => {
          onReset(values, formikHelpers)
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('invalid email')
            .test((value) => isEmail(value))
            .required('mandatory field'),
          password: Yup.string()
            .min(8, 'password required')
            .required('mandatory field')
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
              'required characters'
            ),
        })}
      >
        {({
          errors,
          values,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <div className='w-full md:w-2/3 m-auto'>
            {status === 'success' && (
              <p className='uppercase text-textDarkBlue'>{t(message)}</p>
            )}
            <h4 className='font-diodrumSemibold text-2xl text-black mt-2 mb-4'>
              {status === 'success'
                ? t('you will be redirected to the login page')
                : t('your new password')}
            </h4>
            <form onSubmit={handleSubmit}>
              {status !== 'success' && (
                <>
                  <TextFieldComponent
                    uppername='E-Mail'
                    startIcon='/images/icons/email-icon.png'
                    placeholder={`${values.email}`}
                    type='text'
                    name='email'
                    value={`${values.email}`}
                    className='w-full flex justify-start h-13 rounded-3xl border border-slate-300 text-md pl-3'
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    errorMessage={errors.email}
                    disabled={true}
                  />
                  <TextFieldComponent
                    uppername={t('password')}
                    startIcon='/images/icons/key-icon.png'
                    endIcon={
                      <VisibilityIcon
                        sx={{ color: '#dfe2e6' }}
                        onClick={handleOpenText}
                      />
                    }
                    placeholder={t('your new password')}
                    type={openText ? 'text' : 'password'}
                    name='password'
                    value={values.password}
                    className='w-full h-13 flex justify-start rounded-3xl border border-slate-300 text-md pl-3'
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    errorMessage={errors.password}
                  />{' '}
                </>
              )}

              {resetPassword ? (
                <Link
                  to={'/login'}
                  className='bg-button-red hover:bg-red rounded-3xl w-2/4 h-12 text-lg text-white mt-5 inline-flex justify-center items-center'
                >
                  {loading ? <Loader text={t('loading') + '...'} /> : 'Login'}
                </Link>
              ) : (
                <button
                  type='submit'
                  value='rtl'
                  className='bg-button-red hover:bg-red rounded-3xl w-2/4 h-12 text-lg text-white mt-5 inline-flex justify-center items-center'
                >
                  {loading ? (
                    <Loader text={t('loading') + '...'} />
                  ) : (
                    t('reset password')
                  )}
                </button>
              )}
              {status !== 'success' && (
                <div className='mt-3 mb-1 text-red text-xs h-4 '>
                  <p className='text-red' error>
                    {t(message)}
                  </p>
                </div>
              )}
            </form>
          </div>
        )}
      </Formik>
      <div className='w-auto'>
        <Link to='/privacy-policy' title={t('privacy tooltip')}>
          <a className='text-sm font-bold p-1 capitalize'>
            {t('privacy policy')}
          </a>
        </Link>
      </div>
      <Cookie
        position='absolute'
        width='w-full'
        marginBottom='bottom-0'
        boxShadow='shadow-none'
      />
    </div>
  )
}

const ResetPassword = () => {
  return (
    <UnAuthLayout>
      <ResetPasswordContent />
    </UnAuthLayout>
  )
}
export default ResetPassword
