import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Page from '../layout/index'
import SplineChart from '../components/chart'
import ProfileCard from '../components/cards/profile'
import BuildingUsers from '../components/cards/building-users'
import ActivitiesCard from '../components/cards/activities'
import Popup from '../components/popup'
import Dropdown from '../components/dropdown'
import { formatDate, formatDateWithTime } from '../helpers'
import usePopupVisible from '../hooks/usePopupVisible'
import { DatePicker } from '../components/calendar'
import api from '../api'
import subDays from 'date-fns/subDays'
import CalendarIcon from '../icons/calendar-icon'
import { useTranslation } from 'react-i18next'

export default function Dashboard() {
  const { t, i18n } = useTranslation()
  const [isDropdownOpened, setDropdownOpen] = useState(false)
  const [isCalendarOpened, setCalendarOpen] = useState(false)
  const [dateRange, setDateRange] = useState()
  const [selectedTimeName, setSelectedTimeName] = useState(t('today'))
  const { ref, isPopupVisible, setIsPopupVisible } = usePopupVisible(false)

  const currentDate = new Date()
  const yesterday = subDays(currentDate, 1)

  const handleOpenDropdown = () => {
    setDropdownOpen(true)
    setIsPopupVisible(true)
  }

  const handleCalendarOpen = () => {
    setCalendarOpen(!isCalendarOpened)
    setIsPopupVisible(true)
    setDropdownOpen(false)
  }

  const handleToday = () => {
    const At12AClock = formatDateWithTime(currentDate)
    const now = formatDateWithTime(
      currentDate,
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    )
    const dateRange = {
      startDate: At12AClock,
      endDate: now
    }
    setSelectedTimeName(t('today'))
    setDateRange(dateRange)
    setIsPopupVisible(false)
    setDropdownOpen(false)
  }

  const handleYesterday = () => {
    const yesterdayAt12AClock = formatDateWithTime(yesterday)
    const yesterdayAttheEndOfDay = formatDateWithTime(yesterday, 23, 59, 59)
    const dateRange = {
      startDate: yesterdayAt12AClock,
      endDate: yesterdayAttheEndOfDay
    }
    setSelectedTimeName(t('yesterday'))
    setDateRange(dateRange)
    setIsPopupVisible(false)
    setDropdownOpen(false)
  }
  const handleLastWeek = () => {
    const thisMonday = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
    )
    const lastSunday = formatDateWithTime(subDays(thisMonday, 1), 23, 59, 59)
    const lastMonday = formatDateWithTime(subDays(thisMonday, 8))
    setSelectedTimeName(t('1 week ago'))
    const dateRange = {
      startDate: lastMonday,
      endDate: lastSunday
    }
    setDateRange(dateRange)
    setIsPopupVisible(false)
    setDropdownOpen(false)
  }

  const handleClosePopup = () => {
    setIsPopupVisible(false)
    setCalendarOpen(false)
    setDropdownOpen(false)
  }
  const startDate = formatDate(dateRange?.startDate)
  const endDate = formatDate(dateRange?.endDate)
  const start_date_cal = formatDate(dateRange?.startDate, true, true)
  const end_date_cal = formatDate(dateRange?.endDate, true, true)
  const [loading, setLoading] = useState(true)
  const [dataDraftCheckin, setDataDraftCheckin] = useState({})
  const [dataCheckIn, setDataCheckIn] = useState({})
  const [dataCheckOut, setDataCheckOut] = useState({})
  const now = formatDateWithTime(
    currentDate,
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds()
  )
  const at12AClock = formatDateWithTime(currentDate)
  const start_date = start_date_cal
    ? start_date_cal
    : formatDate(at12AClock, true, true)
  const end_date = end_date_cal ? end_date_cal : formatDate(now, true, true)
  const getChecklnData = React.useCallback(async () => {
    try {
      const response = await api.get(
        `visitor/statistical-data-check-in?start_date=${start_date}&end_date=${end_date}`
      )
      setLoading(false)
      setDataCheckIn(response.data)
    } catch {
      setLoading(false)
    }
  }, [start_date, end_date])
  const getCheckoutData = React.useCallback(async () => {
    try {
      const response = await api.get(
        `visitor/statistical-data-check-out?start_date=${start_date}&end_date=${end_date}`
      )
      setLoading(false)
      setDataCheckOut(response.data)
    } catch {
      setLoading(false)
    }
  }, [start_date, end_date])

  const getDraftCheckinData = React.useCallback(async () => {
    try {
      const response = await api.get(
        `visitor/statistical-data-draft-check-in?start_date=${start_date}&end_date=${end_date}`
      )
      setLoading(false)
      setDataDraftCheckin(response.data)
    } catch {
      setLoading(false)
    }
  }, [start_date, end_date])

  useEffect(() => {
    getChecklnData()
    getCheckoutData()
    getDraftCheckinData()
  }, [getChecklnData, getCheckoutData, getDraftCheckinData])

  useEffect(() => {
    if (
      (i18n.language === 'de' && selectedTimeName === 'Today') ||
      (i18n.language === 'en' && selectedTimeName === 'Heute')
    )
      setSelectedTimeName(t('today'))
    if (
      (i18n.language === 'de' && selectedTimeName === 'Yesterday') ||
      (i18n.language === 'en' && selectedTimeName === 'Gestern')
    )
      setSelectedTimeName(t('yesterday'))
    if (
      (i18n.language === 'de' && selectedTimeName === '1 Week ago') ||
      (i18n.language === 'en' && selectedTimeName === 'Vor 1 Woche')
    )
      setSelectedTimeName(t('1 week ago'))
  }, [i18n.language])

  const Total = (status, data) => {
    const dataTotal = data?.total?.find((item) => item.status === status)
    return dataTotal
  }

  const Persentage = (status, data) => {
    const dataPercentage = data?.percentages?.find(
      (item) => item?.status === status
    )
    return dataPercentage
  }

  const chartsData = [
    {
      colors: ['#558eed', '#96b0ef', '#4d79e4'],
      series: [
        {
          name: t('checked in'),
          data: dataDraftCheckin?.statisticsPerDay?.map(
            (status) => status.draft_check_in
          ),
          style: 'capitalize'
        }
      ],
      label: t('checked in'),
      value: Total('draft_check_in', dataDraftCheckin)?.count || 0,
      rate: Math.round(
        Persentage('draft_check_in', dataDraftCheckin)?.percentage
      ),
      categories: dataDraftCheckin?.statisticsPerDay?.map((datas) => {
        return datas.date
      }),
      textTransform: 'capitalize'
    },
    {
      colors: ['#26b226', '#5ec75e', '#b8ecb8'],
      series: [
        {
          name: t('checked out'),
          data: dataCheckOut?.statisticsPerDay?.map(
            (status) => status.check_out
          ),
          style: 'capitalize'
        }
      ],
      label: t('checked out'),
      value: Total('check_out', dataCheckOut)?.count || 0,
      rate: Math.round(Persentage('check_out', dataCheckOut)?.percentage),
      categories: dataCheckOut?.statisticsPerDay?.map((datas) => {
        return datas.date
      }),
      textTransform: 'capitalize'
    },
    {
      colors: ['#9331c2', '#ca99e2', '#e8d4f1'],
      series: [
        {
          name: t('currently in building'),
          data: dataCheckIn?.statisticsPerDay?.map((status) => status.check_in)
        }
      ],
      label: t('currently in building'),
      value: Total('check_in', dataCheckIn)?.count || 0,
      rate: Math.round(Persentage('check_in', dataCheckIn)?.percentage),
      categories: dataCheckIn?.statisticsPerDay?.map((datas) => {
        return datas.date
      }),
      textTransform: 'capitalize-first-letter::first-letter'
    }
  ]

  const getSelectedDate = () => {
    if (selectedTimeName === '' && startDate === endDate) {
      if (
        startDate !== undefined &&
        startDate !== formatDate(currentDate) &&
        startDate !== formatDate(yesterday)
      )
        return `${startDate}`
      if (startDate === undefined) return t('today')
    }
    if (
      startDate &&
      startDate === endDate &&
      startDate === formatDate(currentDate)
    )
      return t('today')
    if (
      startDate &&
      startDate === endDate &&
      startDate === formatDate(yesterday)
    )
      return t('yesterday')
    if (selectedTimeName) return selectedTimeName

    return `${startDate} - ${endDate}`
  }

  return (
    <Page pageName={t('overview')}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pb: 3,
          pr: 2,
          pl: 1
        }}
      >
        <Box>
          <h4 className='text-textLightBlue text-base uppercase mt-3 ml-5'>
            {t('evaluation')}
          </h4>
        </Box>

        <Box width={180}>
          <button
            className='bg-white w-full p-2 text-lg rounded-lg flex cursor-pointer'
            onClick={() => handleOpenDropdown()}
          >
            <CalendarIcon className='ml-1 mt-1' />

            <p className='ml-2 text-sm text-textBlack font-diodrumMedium'>
              {getSelectedDate()}
            </p>
          </button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {chartsData?.map((chart) => (
          <Grid item xs={4}>
            <SplineChart
              label={chart.label}
              value={chart.value}
              series={chart.series}
              rate={chart.rate}
              colors={chart.colors}
              categories={chart.categories}
              textTransform={chart.textTransform}
            />
          </Grid>
        ))}
        <Grid item xs={5}>
          <Box>
            <ProfileCard name='Max Mustermann' email='info@max.de' />
          </Box>
          <Box>
            <BuildingUsers />
          </Box>
        </Grid>
        <Grid item xs={7} padding={2}>
          <ActivitiesCard />
        </Grid>
      </Grid>
      <Popup open={isPopupVisible} handleClose={handleClosePopup}>
        <div ref={ref}>
          {isDropdownOpened ? (
            <Dropdown
              position='top-[89px] right-10'
              collaspeItem={{
                label: getSelectedDate(),
                startIcon: <CalendarIcon className='ml-1 mt-1' />
              }}
              width={180}
              handleClose={handleClosePopup}
              dropdownItems={[
                {
                  label: t('today'),
                  onClick: handleToday
                },
                { label: t('yesterday'), onClick: handleYesterday },
                { label: t('1 week ago'), onClick: handleLastWeek },
                {
                  label: t('date'),
                  onClick: handleCalendarOpen,
                  onClickValue: isCalendarOpened,
                  endIcon: <CalendarIcon className='m-auto' />
                }
              ]}
            />
          ) : (
            <div className='absolute top-32 mt-1 right-10'>
              <DatePicker
                dateRange={dateRange}
                setDateRange={setDateRange}
                setOpen={() => {
                  setCalendarOpen()
                  handleClosePopup()
                }}
                setTimeName={setSelectedTimeName}
                dashboard={true}
              />
            </div>
          )}
        </div>
      </Popup>
    </Page>
  )
}
