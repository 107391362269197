import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Document, Page, pdfjs } from 'react-pdf'
import Cookie from '../components/cookie'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export default function PrivacyPolicy() {
  const [pageNumber, setPageNumber] = useState(1)
  const [privacyPathLang, setPrivacyPathLang] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(numPages)
  }

  const { t, i18n } = useTranslation()

  const handleGoBack = () => {
    if (location.state && location.state === 'confirmationPage') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (i18n.language === 'en') {
      setPrivacyPathLang(
        `${
          process.env.REACT_APP_S3_URL
        }/privacy-policy/privacyPolicyEn.pdf?${Date.now()}`
      )
    } else {
      setPrivacyPathLang(
        `${
          process.env.REACT_APP_S3_URL
        }/privacy-policy/privacyPolicyDe.pdf?${Date.now()}`
      )
    }
  }, [i18n.language])

  return (
    <div className='flex flex-col min-h-screen h-full justify-between p-10 relative'>
      <div>
        <div className='flex justify-between w-1/2'>
          <Link to={'/'} title='Return to dashboard'>
            <a>
              <img className='w-32 m-auto' src='/images/dupont-logo.png' />
            </a>
          </Link>

          {
            <button onClick={handleGoBack}>
              <ArrowBackIcon />
              <span>
                {location.state === 'confirmationPage'
                  ? t('go to overview page')
                  : t('go back')}
              </span>
            </button>
          }
        </div>
        <div className='mt-16'>
          <h1 className='text-2xl text-black font-bold my-7'>
            {t('privacy_policy')}
          </h1>
          <Document
            file={privacyPathLang}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.apply(null, Array(pageNumber))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page pageNumber={page} />
              ))}
          </Document>
        </div>
      </div>
      <h4 className='text-sm text-black font-bold'>{t('privacy_policy')}</h4>

      <Cookie
        position='fixed'
        width='w-3/5'
        marginBottom='bottom-10'
        boxShadow='shadow-2xl	'
      />
    </div>
  )
}
