import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Search from '@mui/icons-material/Search'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '../calendar'
import Popup from '../popup'
import Dropdown from '../dropdown'
import SearchComponent from '../search'
import TableBody from './body'
import TableHeader from './header'
import usePopupVisible from '../../hooks/usePopupVisible'
import CalendarIcon from '../../icons/calendar-icon'
import FilterMenu from '../filter-menu'

export default function DataTable({
  headCells,
  totalCounts,
  rows,
  hasSort,
  hasFilter,
  hasPagination,
  printOption,
  dataType,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  getData,
  dateRange,
  setDateRange,
  table,
  filterName,
  setFilterName,
  setFilterDirection,
  filterDirection
}) {
  const [selected, setSelected] = React.useState([])
  const [isDropdownOpenOpen, setDropdownOpenOpen] = useState(false)
  const [isSearchOpen, setSearchOpen] = useState(false)
  const [isCalendarOpened, setCalendarOpen] = useState(false)

  const { t } = useTranslation()

  const { ref, isPopupVisible, setIsPopupVisible } = usePopupVisible(false)
  const { searchQuery } = useSelector((state) => state.search)

  const handleTogglePopup = () => {
    setIsPopupVisible(!isPopupVisible)
    setSearchOpen(false)
  }
  const handleToggleSearch = () => setSearchOpen(!isSearchOpen)

  const handleRequestSort = (event, property) => {
    const isAsc = filterName === property && filterDirection === 'asc'
    setFilterDirection(isAsc ? 'desc' : 'asc')
    setFilterName(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    getData(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleCalendarOpen = () => {
    setCalendarOpen(!isCalendarOpened)
  }

  useEffect(() => {
    setIsPopupVisible(isDropdownOpenOpen)
  }, [isDropdownOpenOpen])

  useEffect(() => {
    !isPopupVisible && setDropdownOpenOpen(false)
    !isPopupVisible && setCalendarOpen(false)
  }, [isPopupVisible])

  // Define custom margin values (in pixels)
  const marginTop = 80
  const marginRight = 30
  const marginBottom = 20
  const marginLeft = 0

  // Generate a dynamic CSS class for the custom margin
  const marginStyle = `
    @media print {
      @page {
        margin: ${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px;
      }
      body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
    }
  `

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          pb: 2,
          boxShadow: 'none',
          borderRadius: '20px',
          overflow: 'auto'
        }}
      >
        <TableContainer id='table' style={{ paddingBottom: '20px' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size={'medium'}
          >
            <TableHeader
              headCells={headCells}
              totalCounts={totalCounts}
              hasFilter={hasFilter}
              numSelected={selected?.length}
              order={filterDirection}
              orderBy={filterName}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              popupOpen={isPopupVisible && isDropdownOpenOpen}
              handleClosePopup={() => {
                handleTogglePopup()
                setDropdownOpenOpen(!isDropdownOpenOpen)
              }}
              table={table}
            />
            <TableBody
              dataType={dataType}
              searchQuery={searchQuery}
              hasSort={hasSort}
              hasFilter={hasFilter}
              rows={rows}
              order={filterDirection}
              orderBy={filterName}
              selected={selected}
              page={page}
              rowsPerPage={rowsPerPage}
              setSelected={setSelected}
              getData={getData}
              table={table}
            />
          </Table>
        </TableContainer>

        {hasPagination && (
          <TablePagination
            showFirstButton={true}
            showLastButton={true}
            rowsPerPageOptions={[]}
            component='div'
            count={totalCounts}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              return '' + from + '–' + to + ' ' + t('of') + ' ' + count
            }}
          />
        )}
        {printOption && (
          <div className='text-right mr-8 my-4 ' id='print-option'>
            <style>{marginStyle}</style>

            <button
              className='text-sm text-white bg-green p-3 rounded-md'
              onClick={() => window.print()}
            >
              {printOption.label}
            </button>
          </div>
        )}
      </Paper>
      <Popup
        open={isPopupVisible && isDropdownOpenOpen}
        handleClose={handleTogglePopup}
      >
        <div ref={ref}>
          {!isSearchOpen && !isCalendarOpened ? (
            <Dropdown
              width={200}
              position={`${
                table === 'check-ins' ? 'right-20' : 'right-10'
              } top-36 pl-10 pt-1`}
              handleClose={handleTogglePopup}
              dropdownItems={[
                {
                  label: t('search'),
                  onClick: handleToggleSearch,
                  endIcon: (
                    <Search sx={{ color: '#a7a7be', marginTop: '1px' }} />
                  )
                },
                {
                  label: t('period'),
                  onClick: handleCalendarOpen,
                  onClickValue: isCalendarOpened,
                  endIcon: <CalendarIcon />
                }
              ]}
            />
          ) : isSearchOpen ? (
            table === 'check-ins' ? (
              <div
                className={`absolute top-36 mt-1 ${
                  table === 'check-ins' ? 'right-20' : 'right-10'
                } w-auto h-auto bg-white inline-block text-left rounded-lg w-auto`}
              >
                <SearchComponent
                  handleClose={() => {
                    handleTogglePopup()
                    handleToggleSearch()
                  }}
                  searchQuery={searchQuery}
                  position='relative right-auto'
                  paddingBottom={1}
                />
                <FilterMenu width={387} handleClose={handleTogglePopup} />
              </div>
            ) : (
              <SearchComponent
                handleClose={() => {
                  handleTogglePopup()
                  handleToggleSearch()
                }}
                searchQuery={searchQuery}
                position={`top-36 mt-1 ${
                  table === 'check-ins' ? 'right-20' : 'right-10'
                }`}
              />
            )
          ) : (
            isCalendarOpened && (
              <div
                className={`absolute top-36 mt-1 ${
                  table === 'check-ins' ? 'right-16' : 'right-5'
                }`}
              >
                <DatePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setOpen={() => {
                    setCalendarOpen()
                    handleTogglePopup()
                  }}
                />
              </div>
            )
          )}
        </div>
      </Popup>
    </Box>
  )
}
