import React from 'react'

const UnAuthLayout = ({ children }) => (
  <div className='grid grid-cols-2 h-screen'>
    {children}
    <div
      style={{
        backgroundImage: `url("/images/DuPont Background.png")`
      }}
      className='bg-cover bg-no-repeat flex justify-center items-end'
    >
      <div className='p-10'>
        <img
          src='/images/dupont-white-logo.png'
          alt='image 0'
          loading='lazy'
          className='w-60 h-auto'
        />
      </div>
    </div>
  </div>
)

export default UnAuthLayout
