import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import DataTable from '../components/table'
import Page from '../layout'
import api from '../api'
import {
  formatDate,
  formatTimeToISOString,
  isValidTimeFormat
} from '../helpers'
import { useTranslation } from 'react-i18next'

export default function Activities() {
  const [activities, setActivities] = useState([])
  const [totalActivities, setTotalActivities] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(9)
  const [dateRange, setDateRange] = useState()
  const [filterName, setFilterName] = useState('')
  const [filterDirection, setFilterDirection] = useState('')

  const { t } = useTranslation()

  const startDate = formatDate(dateRange?.startDate, true, true) || ''
  const endDate = formatDate(dateRange?.endDate, true, true) || ''
  const { searchQuery } = useSelector((state) => state.search)

  const getActivities = useCallback(
    async (page) => {
      const searchTime = isValidTimeFormat(searchQuery)
        ? formatTimeToISOString(searchQuery)
        : searchQuery

      await api
        .get(
          `visitor?visitorHistory=false&page=${page}&limit=${rowsPerPage}${
            startDate && `&startDate=${startDate}`
          }${
            endDate && `&endDate=${endDate}`
          }&search=${searchTime}&filterName=${filterName}&filterDirection=${filterDirection}`
        )
        .then(async (response) => {
          if (response.data) {
            setActivities(response.data.results)
            setTotalActivities(response.data.total)
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    [startDate, endDate, searchQuery, filterName, filterDirection]
  )

  useEffect(() => {
    getActivities(page)
  }, [getActivities, filterName, filterDirection])

  return (
    <Page pageName={t('activities')}>
      <div className='h-auto min-h-screen'>
        <DataTable
          table='history'
          hasSort={true}
          hasFilter={true}
          hasPagination={true}
          headCells={[
            {
              left: true,
              disableSorting: true,
              id: 'name',
              label: t('last activities'),
              className: `capitalize ${
                activities?.length > 0 ? '-ml-20 pl-1' : '-ml-11'
              }`
            },
            {
              filterName: 'type',
              filterDirection: filterDirection,
              id: 'type',
              numeric: false,
              disablePadding: false,
              label: t('type')
            },
            {
              filterName: 'check_in_period',
              filterDirection: filterDirection,
              id: 'checkInStartDate',
              numeric: true,
              disablePadding: false,
              label: t('check in/out period')
            },
            {
              filterName: 'status',
              filterDirection: filterDirection,
              id: 'status',
              numeric: false,
              disablePadding: false,
              label: 'Status'
            }
          ]}
          rows={activities}
          totalCounts={totalActivities}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          filterName={filterName}
          setFilterName={setFilterName}
          filterDirection={filterDirection}
          setFilterDirection={setFilterDirection}
          setRowsPerPage={setRowsPerPage}
          getData={getActivities}
          dateRange={dateRange}
          setDateRange={setDateRange}
          dataType='read-only'
        />
        {activities && activities.length === 0 && (
          <p className='block p-1 pl-2 m-3 bg-lightGray'>{t('no data')}</p>
        )}
      </div>
    </Page>
  )
}
