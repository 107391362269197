import { MenuItem, Select } from '@mui/material'
import { color, fontFamily, fontSize } from '@mui/system'
import { Field, useFormikContext } from 'formik'
import { useState } from 'react'

const RightAnswerSelectField = ({
  numb,
  error,
  name,
  type,
  placeholder,
  onChange,
  onClick,
  value,
  options
}) => {
  const formikProps = useFormikContext()
  const [value1, setValue1] = useState(value)
  return (
    <div className='flex flex-row justify-start items-start  w-full h-full'>
      <Select
        className='questionSelect w-full h-10 text-red'
        inputProps={{ IconComponent: () => null, placeholder: placeholder }}
        sx={{
          color: 'inherit',
          fontFamily: 'inherit',
          fontSize: '13px',
          paddingTop: '0px',
          boxShadow: 'none',
          marginRight: '10px',

          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 1
          },

          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              border: 0
            },
          "&.MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
            {
              backgroundColor: 'black'
            }
        }}
        labelId='demo-simple-select-label'
        id='position'
        value={value}
        name={name}
        onChange={(e) => {
          setValue1(e.target.value)
          formikProps.setFieldValue(name, e.target.value)
        }}
        error={error}
      >
        {options?.map((option) => (
          <MenuItem
            style={{
              color: 'inherit',
              fontSize: '13px',
              fontFamily: 'inherit'
            }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default RightAnswerSelectField
